<template>
  <header class="header-top" header-theme="light">
    <div class="container-fluid">
      <div class="d-flex justify-content-between">
        <div class="top-menu d-flex align-items-center">
          <button type="button" class="btn-icon mobile-nav-toggle d-lg-none">
            <span></span>
          </button>
          <!--<div class="header-search">
                        <div class="input-group">
                            <span class="input-group-addon search-close"><i class="ik ik-x"></i></span>
                            <input type="text" class="form-control">
                            <span class="input-group-addon search-btn"><i class="ik ik-search"></i></span>
                        </div>
                    </div>-->
          <!-- <button type="button" id="navbar-fullscreen" class="nav-link"><i class="ik ik-maximize"></i></button> -->
        </div>
        <div class="top-menu d-flex align-items-center">
          <div class="dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="notiDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              ><i class="ik ik-bell"></i
              ><span class="badge bg-danger">{{
                notifications.length > 9 ? "9+" : notifications.length
              }}</span></a
            >
            <div
              class="dropdown-menu dropdown-menu-right notification-dropdown"
              aria-labelledby="notiDropdown"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-270px, 30px, 0px);
              "
            >
              <h4
                class="header d-flex justify-content-between align-items-center"
              >
                Notifications
                <b-button
                  v-if="'default' == notifPermission"
                  size="sm"
                  variant="danger"
                  @click.prevent="askNotificationPermissions"
                  class="px-2"
                  v-b-tooltip.bottopm="
                    'Etre avertir d\'une notification même si vous n\'êtes pas dans l\'application'
                  "
                  >Notifications de bureau</b-button
                >
              </h4>
              <div class="notifications-wrap">
                <a :href="`${$router.options.base}${notif.url}`" class="media" v-for="(notif ,i) in notifs" :key="notif.idAlerte || i">
                  <span class="d-flex">
                    <i class="ik ik-check"></i>
                  </span>
                  <span class="media-body">
                    <span class="heading-font-family media-heading"
                      >{{ notif.libelle }}
                      <time class="float-right small">{{
                        $dayjs(notif.createdAt).format("MM/YYYY - HH:mm")
                      }}</time>
                    </span>
                    <span class="media-content d-inline-block w-100">{{
                      notif.description | truncate(50)
                    }}</span>
                  </span>
                </a>
                <b-container v-if="!notifs.length" class="mt-1">
                  <b-alert variant="info" class="text-center" show>
                    Aucune nouvelle notification disponible
                  </b-alert>
                </b-container>
              </div>
              <div class="footer">
                <b-link :to="{ name: 'notifications' }"
                  >Voir toutes les notifications</b-link
                >
              </div>
            </div>
          </div>
          <div class="dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="notiDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              ><i class="fa fa-briefcase"></i
            ></a>
            <div
              class="dropdown-menu dropdown-menu-right notification-dropdown"
            >
              <h4 class="header">Entreprises</h4>
              <div class="notifications-wrap">
                <a
                  href="#"
                  @click.prevent="selectEnterprise(entreprise)"
                  class="media"
                  v-for="(entreprise, i) in entreprises"
                  :key="i"
                >
                  <span class="d-flex">
                    <b-avatar :src="entreprise.logo" />
                  </span>
                  <span class="media-body">
                    <span
                      class="heading-font-family media-heading h6"
                      :class="{
                        'text-danger':
                          selected_enterprise == entreprise.idEntreprise,
                      }"
                      >{{ entreprise.nom }}</span
                    >
                    <span class="media-content d-inline-block w-100">{{
                      entreprise.Employes.poste
                    }}</span>
                  </span>
                  <span v-if="entreprise.idEntreprise == selected_enterprise">
                    <b-button variant="none"
                      ><i class="fa fa-check fa-2x" aria-hidden="true"></i
                    ></b-button>
                  </span>
                </a>
              </div>
              <div class="footer">
                <b-link :to="{ name: 'select-entreprise' }"
                  >Voir toutes les entreprises</b-link
                >
              </div>
            </div>
          </div>
          <!--<button type="button" class="nav-link ml-10" id="apps_modal_btn" data-toggle="modal" data-target="#appsModal"><i class="ik ik-grid"></i></button>-->
          <div class="dropdown">
            <!-- <a class="dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img class="avatar" :src="user.avatar" :alt="user.nom"></a> -->
            <a
              class="dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              ><img
                @error="errorImg()"
                class="avatar"
                :src="currentCompany.logo"
                style="object-fit: cover"
                :alt="currentCompany.nom"
            /></a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="userDropdown"
            >
              <b-link class="dropdown-item" :to="{ name: 'profil' }"
                ><i class="ik ik-user dropdown-icon"></i>
                {{ $t("data.header_profil") }}</b-link
              >
              <b-link class="dropdown-item" :to="{ name: 'messages' }"
                ><i class="fas fa-comment mr-2"></i> Messages</b-link
              >
              <a class="dropdown-item" href="#" @click.prevent="logout"
                ><i class="ik ik-power dropdown-icon"></i>
                {{ $t("data.header_deconnexion") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      size="sm"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      id="modal-switch-enterprise"
    >
      <div
        class="
          py-5
          w-100
          d-flex
          flex-column
          justify-content-center
          align-items-center
        "
      >
        <b-spinner />
        <h5 class="my-2">
          Migration vers <b>{{ entreprise.nom }}</b>
        </h5>
        <p>Chargement des données en cours...</p>
      </div>
    </b-modal>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

const php = require("phpjs");

export default {
  name: "Header",
  data: () => ({
    entreprise: {},
    currentCompany:{},
    notifPermission:
      "Notification" in window ? Notification.permission : "denied",
    avatar: "/img/profile-picture.jpg",
  }),
  computed: {
    ...mapGetters(["user", "notifications"]),
    entreprises() {
      return php.array_slice(this.user.entreprises, 0, 5);
    },
    notifs() {
      return php.array_slice(this.notifications, 0, 5);
    },
    selected_enterprise() {
      return storage.get("selected_enterprise");
    },
  },

  methods: {
    logout() {
      storage.clear();
      this.$store.dispatch("user", {});
      this.$router.push({ name: "login" });
    },
    selectEnterprise(entreprise) {
      if (entreprise.idEntreprise != this.selected_enterprise) {
        this.entreprise = entreprise;
        this.$bvModal.show("modal-switch-enterprise");
        storage.set("selected_enterprise", entreprise.idEntreprise);

        let privileges = [];
        for (const role of entreprise.Employes.roles) {
          privileges = [...privileges, ...role.privileges];
        }
        this.$store.dispatch("permissions", privileges);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    },
    errorImg() {
      this.currentCompany.logo = "/img/profile-picture.jpg";
    },

    /**
     * Demande dde permissions pour les notification push
     */
    async askNotificationPermissions() {
      if ("Notification" in window && "serviceWorker" in navigator) {
        this.notifPermission = await Notification.requestPermission();
        if (this.notifPermission === "granted") {
          this.registerServiceWorker();
        }
      }
    },
    async registerServiceWorker() {
      const registration = await navigator.serviceWorker.register(
        "/service-worker.js"
      );
      let subscription = await registration.pushManager.getSubscription();
      if (!subscription) {
        subscription = registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: await this.getPublicVapidKey(),
        });
      }
      await this.saveSubscription(subscription);
    },
    async getPublicVapidKey() {
      return await axios.get("web-push/key").then((response) => response.key);
    },
    async saveSubscription(subscription) {
      await axios.post(
        "web-push/subscribe",
        JSON.parse(JSON.stringify(subscription))
      );
    },
  },
  mounted() {
    this.avatar =
      this.user.avatar || "/img/profile-picture.jpg";
      console.log(this.user);
      this.currentCompany = this.user.entreprises.find(element => element.idEntreprise == this.selected_enterprise);
      console.log(this.currentCompany);
  },
};
</script>
