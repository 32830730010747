export default {
    sidebar_acueil: 'Accueil',
    sidebar_dashboard: 'Tableau de bord',
    sidebar_patrimoine_immobilier: 'Patrimoine immobilier',
    sidebar_mes_cites: 'Mes cités',
    sidebar_mes_batiments: 'Mes bâtiments',
    sidebar_mes_logements: 'Mes logements',
    sidebar_gestion_immobiliere: 'Gestion Immobilière',
    sidebar_promotion_immobiliere:'Promotion Immobilière',
    sidebar_reservations:'Reservations',
    sidebar_mes_annonces: 'Mes annonces',
    sidebar_discussions:'Mes échanges',
    sidebar_mes_occupations: 'Mes occupations',
    sidebar_locations: 'Locations',
    sidebar_mes_habitants: 'Mes habitants',
    sidebar_insolvables: 'Insolvables',
    sidebar_analyse_rentabilite: 'Analyse & Rentabilité',
    sidebar_depenses:'Dépenses',
    sidebar_notifications:'Notifications',
    sidebar_finances:'Finances',
    sidebar_releves_indexes: 'Relevés d\'indexes',
    sidebar_loyers: 'Loyers',
    sidebar_recettes:'Recettes',
    sidebar_recharge_comptes:'Recharge des comptes',
    sidebar_gestion_caution:'Gestion des cautions',
    sidebar_configuration_aide: 'Documentation et aide',
    sidebar_modeles_contrats: 'Modèles de contrats',
    sidebar_documentation: 'Documentation',
    sidebar_parametres:'Paramètres',
    sidebar_preferences: 'Paramètres généraux',
    sidebar_formules_dutilisation: 'Abonnements',
    sidebar_gestion_locative:'Gestion locative',
    
    //messages de la homepage
    home_bienvenu: 'Bienvenu sur la plate-forme Maisonier',
    home_paragraphe_informatif1: 'Ici vous pouvez créer des: cités, bâtiments, logements, habitants. Mais avant, veuillez compléter votre profil ',
    home_paragraphe_informatif2 :'Profil complet à',
    home_terminer_profil: 'Terminer votre profil',
    home_batiments: 'Bâtiments',
    home_aucun_batiment: ' Aucun batiment pour le moment',
    home_batiments_crees: 'batiment',
    home_logements: 'Logements',
    home_pas_de_logements: 'Aucun logement crée pour le moment',
    home_logements_crees: ' logement',
    home_habitants: 'Habitants',
    home_pas_dhabitants: 'Pas d\'habitant pour le moment',
    home_locataires_crees: 'locataire',
    home_actions_rapides: 'Actions rapides',
    home_creer_une_cite: 'Ajouter une cité',
    home_descripton_cite : 'Une cité est un ensemble de plusieurs bâtiments regroupés dans une zone',
    home_creer: 'Ajouter',
    home_creer_batiment : 'Ajouter un bâtiment',
    home_description_batiment : ' Un bâtiment est un édifice doté de plusieurs résidences (appartements, studios etc)',
    home_creer_logement : 'Ajouter un logement',
    home_description_logement : 'Un logement est une residence destiné a hébergé un habitant (ex appartement, maison, studio)',
    home_creer_habitant : 'Ajouter un habitant',
    home_description_habitant: 'Un habitant est tout simplement une personne (votre locataire) qui occupe votre logement',


    //messages du dashbord
    dashbord_logements: 'Logements',
    dashbord_disponibles: 'disponibles',
    dashbord_occupe: 'occupé',
    dashbord_locataires: 'Locataires',
    dashbord_actifs: 'actifs',
    dashbord_actif: 'actif',
    dashbord_evenements: 'Evènements',
    dashbord_total_evenement: 'Total Evènements',
    dashbord_commentaires: 'Commentaires',
    dashbord_total_commentaire: 'Total commentaires',
    dashbord_recouvrement_du_mois: 'Recouvrements du mois',
    dashbord_recouvrement_annuel: 'Recouvrements annuel',
    dashbord_Logements_par_batiment: "Logements par bâtiment",

    //messages de page des cités
    cite_ajouter_cite: 'Ajouter une cité',
    cite_pas_de_cite_enregistrer: 'Aucune cité enregistrée pour le moment',
    cite_detail_cite: 'Détails de la cité',
    cite_editer_cite: 'Edition de la cité',
    cite_valider_cite: 'Valider',
    cite_nom_cite: 'Nom',
    cite_exemple_cite: 'Ex: Cité de la paix',
    cite_reference_cite: 'Reference',
    cite_exemple_reference_cite: 'Ex: CP3',
    cite_label_photo: 'Photo',
    cite_ajouter_photo_de_la_cite: 'Ajouter la photo de la cité',
    cite_bottom_text: 'déposez le fichier ici ou cliquez pour modifier',
    
    // messages d'un cité
    cite_new: 'Nouvelle',
    cite_tooltip_details: 'Détails',
    cite_tooltip_editer: 'Editer',
    cite_tooltip_supprimer: 'Supprimer',

    //messages de page de détail de la cité
    cite_detail_batiments: 'Bâtiments',
    cite_detail_stat_generales: 'Statistiques générales',
    cite_detail_ajout_batiments: 'Ajouter un bâtiment',
    cite_detail_depenses_de_la_cite: 'Dépenses liées à la cité',
    cite_detail_ajouter_depense: 'Ajouter une dépense',
    cite_detail_titre_stat_generales: 'Statistiques générales',
    cite_detail_pas_de_batiments: 'Aucun bâtiment enregistré pour le moment',
    cite_detail_pas_de_depenses: 'Aucune dépense enregistrée pour le moment',
    
    
    //messages de page des bâtiments
    batiment_ajouter_batiment: 'Ajouter un bâtiment',
    batiment_editer_batiment: 'Edition du bâtiment',
    batiment_pas_de_batiment: 'Aucun bâtiment enregistré pour le moment',
    batiment_pas_de_batiment_trouve: 'Aucun bâtiment trouvé',
    batiment_details_batiment: 'Détails du bâtiment',
    batiment_analyses_batiment: 'Analyses du bâtiment',
    
    //messages du bâtiment
    batiment_new: 'Nouveau',
    batiment_reference: 'Reference',
    batiment_details: 'Détails',
    batiment_acceder_aux_details: 'Accéder aux détails',
    batiment_editer: 'Editer',
    batiment_supprimer: 'Supprimer',
    
    //messages de page de détail de la cité
    batiment_detail_logements: 'Logements',
    batiment_detail_stat_generales: 'Statistiques générales',
    batiment_detail_ajouter_logement: 'Ajouter un logement',
    batiment_detail_depenses_batiment: 'Dépenses liées au batiment',
    batiment_detail_ajouter_depense: 'Ajouter une dépense',
    batiment_detail_titre_stat_generales: 'Statistiques générales',
    batiment_detail_pas_de_logements: 'Aucun logement enregistré pour le moment',
    batiment_detail_pas_de_depenses: 'Aucune dépense enregistrée pour le moment',
    

    //messages de page de la navbar
    navbar_mes_cites: 'Mes cités',
    navbar_mes_batiments: 'Mes bâtiments',
    navbar_mes_logements: 'Mes logements',

    //messages de page des logements
    Filtre_de_categories: 'Filtre de catégories',
    logement_ajouter_logement: 'Ajouter un logement',
    logement_valider_logement: 'Valider',
    logement_pas_de_logement: 'Aucun logement enregistré pour le moment',
    logement_details_logement: 'Détails du logement',
    logement_analyses_logement: 'Analyses du logement',
    logement_cloner_logement: 'Clonner le logement',
    logement_description_clonage: 'Le clonnage de logement vous permet d\'ajouter une multitude de logement en se servant d\'un logement pré-existant pour générer de nouveaux.',
    logement_nombre_de_clones: 'Entrez le nombre de logement à générer',
    logement_nombre_de_clones_label: 'Nombre de clone',
    logement_batiment_lie_au_clonage: 'Selectionnez le batiment auquel sera rataché les logements générés',
    logement_batiment_lie_au_clonage_label: 'Bâtiment',
    logement_pas_de_batiment: 'Pas de bâtiment',

    //messages du logement
    logement_etat_libre: 'Libre',
    logement_etat_occupe: 'Occupé',
    logement_tooltip_details: 'Détails',
    logement_tooltip_analyses: 'Analyses',
    logement_tooltip_cloner: 'Cloner',
    logement_tooltip_acceder_aux_details: 'Accéder aux détails',
    logement_tooltip_editer: 'Editer',
    logement_tooltip_supprimer: 'Supprimer',
    
    //messages analyses logement
    logement_analyse_vacances_immobiliere: 'Vacances immobilière',

    //messages details logement
    logement_detail_caracteristiques: 'Caractéristiques',

    logement_detail_annonces: 'Annonces',
    logement_detail_depenses: 'Dépenses',
    logement_detail_locataires: 'Locataires',
    logement_detail_localisation: 'Localisation',
    logement_detail_localisation_pays: 'Pays',
    logement_detail_localisation_ville: 'Ville',
    logement_detail_localisation_quartier: 'Quartier',
    logement_detail_caracteristiques_approfondies: 'Caractéristiques approfondies',
    logement_detail_type_logement: 'Type de logements',
    logement_detail_date_du_poste: 'Posté le',
    logement_detail_photos: 'Photos',
    logement_detail_tableau_libelle: 'Libellé',
    logement_detail_tableau_valeur: 'Valeur',
    logement_detail_titre_etat_logement: 'Etat du logement',
    logement_detail_prix_min_logement: 'Prix du minimal',
    logement_detail_prix_min_logement_exemple: 'Prix Minimal Ex:18500',
    logement_detail_prix_max_logement_exemple: 'Prix Maximal Ex:21000',
    logement_detail_prix_min_logement_obligatoire: 'Le prix minimal est obligatoire',
    logement_detail_prix_max_logement_obligatoire: 'Le prix maximal est obligatoire',
    logement_detail_prix_max_logement: 'Prix du maximal',
    logement_detail_superficie: 'Superficie',
    logement_detail_pas_de_annonce: 'Aucune annonce enregistrée pour le moment',
    logement_detail_annonce_creee_le: 'Créee le',
    logement_detail_ajouter_depense: 'Ajouter une dépense',
    logement_detail_pas_de_depense: 'Aucune dépense enregistrée pour le moment',
    logement_detail_pas_de_locataire_dans_le_logement: 'Aucun locataire n\'a occupé ce logement pour le moment',
    logement_detail_bail_en_cours: 'Bail en cours',
    logement_detail_bail_termine_le: 'Bail terminé le',
    logement_detail_details_du_locataire: 'Détails du locataire',
    logement_detail_details_de_occupation: 'Détails de l\'occupation',
    logement_detail_tooltip_nom_et_prenom: 'Nom et prénom',



    // message de la page du profile de l'utilisateur
    profile_certifier_informations: 'Certifier mes informations',
    profile_certifier_informations_description: "Celà consiste à envoyer mes informations pour étude aux administrateurs de la plateforme. Bien vouloir cliquer sur le bouton pour certifier vos infomations",
    profile_nombre_locataire: 'Certifier mes informations',
    profile_nombre_logement_libre_sans_annonce_publie: 'Le nombre de mes logements libres et sans annonce publié',
    profile_address_email: 'E-mail',
    profile_address_email_secondaire: 'E-mail Secondaire',
    profile_address_email_exemple: 'Ex: tagnemiguel@gmail.com',
    profile_address_phone: 'N° Téléphone',
    profile_address_phone_secondaire: 'N° Téléphone secondaire',
    profile_address_phone_exemple: 'Ex: 693803726',
    profile_maisonier_sur_reseaux_sociaux: 'Le maisonier sur les réseaux sociaux',
    profile_maisonier_facebook: 'Accéder à la page Facebook du Maisonier.',
    profile_maisonier_twitter: 'Accéder au compte Twitter du Maisonier.',
    profile_maisonier_instagram: 'Accéder au compte Instagram du Maisonier.',
    profile_maisonier_youtube: 'Accéder à la chaine youtube du Maisonier',
    profile_maisonier_whatsap:'Compte professionnel whatsapp du produit Maisonier',
    profile_activite: 'Activité',
    profile_mes_informations: 'Mes informations',
    profile_nom: 'Nom(s)',
    profile_nom_exemple: 'Ex: TAGNE',
    profile_prenom: 'Prénom(s)',
    profile_address_cni:'N° Pièce d\'identité',
    profile_genre: 'Genre',
    profile_prenom_exemple: 'Ex: Miguel Pedro',
    profile_date_naissance: 'Date de naissance',
    profile_selectionner_date: 'Selectionnez une date',
    profile_profession: 'Profession',
    profile_profession_exemple: 'Eg: Architecte',
    profile_photo_profile: 'Photo de profil',
    profile_modifier_mot_de_passe: 'Modifier mon mot de passe',
    profile_mot_de_passe_actuel: 'Mot de passe Actuel',
    profile_mot_de_passe_nouveau: 'Nouveau mot de passe',
    profile_mot_de_passe_mettre_a_jour: 'Mettre à jour',


    //messages des annonces
    annonce_ajouter_une_annonce: 'Nouvelle annonce',
    annonce_editer_tooltip_annonce: 'Editer',
    annonce_detail_tooltip_annonce: 'Détails',
    annonce_menu_tooltip_annonce: 'Menu',
    annonce_pas_de_annonce: 'Aucune annonce créée pour le moment',
    annonce_details_de_annonce: 'Détails de l\'annonce',
    annonce_details_dupliquer_annonce_de_titre: 'Duplication de l\'annonce de titre',
    annonce_details_dupliquer_annonce_de_titre_valider: 'Valider',
    annonce_details_dupliquer_annonce_description: 'La duplication de cette annonce consistera à l\'appliquer à tous les logements du batiment auquel cette dernière est liée. Pour notre cas, il s\'agit de tous les logements du batiment',
    

    //message de l'annonce
    annonce_details_etat_publiee: 'Déja publiée',
    annonce_details_etat_non_publiee: 'Pas encore publiée',
    annonce_details_tooltip_acceder_au_logement: 'Acceder au logement',
    annonce_details_tooltip_publier_annonce: 'Publier l\'annonce',
    annonce_details_tooltip_dupliquer_annonce: 'Dupliquer l\'annonce',
    annonce_details_tooltip_propositions_annonce: 'Propositions',
    annonce_details_tooltip_commenter_annonce: 'Faire un commentaire',
    annonce_details_tooltip_supprimer_annonce: 'Supprimer l\'annonce',
 
  
    //message détails de l'annonce
    annonce_details_caracteristiques: 'Caractéristiques',
    annonce_details_propositions: 'Propositions',
    annonce_details_localisation: 'Localisation',
    annonce_details_pays: 'Pays',
    annonce_details_ville: 'Ville',
    annonce_details_quartier: 'Quartier',
    annonce_details_caracteristiques_approfondies: 'Caractéristiques approfondies',
    annonce_details_titre: 'Titre',
    annonce_details_poste_le: 'Posté le',
    annonce_details_photos_annonce: 'Photos',
    annonce_details_informations_sur_le_logement: 'Informations sur le logement',
    annonce_details_tableau_libelle: 'Libellé',
    annonce_details_tableau_valeur: 'Valeur',
    annonce_details_prix_logement: 'Prix du logement',
    annonce_details_propositions: 'Propositions',

    // message du formulaire d'annonce
    annonce_form_titre_annonce: 'Titre de l\'annonce',
    annonce_form_label_description_annonce: 'Description',
    annonce_form_label_description_annonce_exemple: 'Caractériser votre annonce afin d\'être bien reférencé',
    annonce_form_titre_annonce_exemple: 'Ex: Grande chambre moderne à louer',
    annonce_form_titre_annonce_description: 'Donnez un titre intuitif à votre annonce',
    annonce_form_label_tags: 'Tags',
    annonce_form_label_tags_exemple: 'Ex: Appartement meublé, grande chambre',
    annonce_form_label_tags_description: 'Ajouter des critères de recherche à votre annonce',
    annonce_form_label_selection_logement: 'Veuillez selectionner le logement',
    annonce_form_publier_annonce: 'Directement publier cette annonce ?',
    annonce_form_validite_annonce: 'Spécifier la durée de validité',
    annonce_form_validite_label_date_debut_annonce: 'Date de début',
    annonce_form_validite_label_date_debut_description_annonce: 'Quand souhaitez-vous débuter la publication',
    annonce_form_validite_label_date_fin_annonce: 'Date de fin',
    annonce_form_validite_label_date_fin_description_annonce: 'Date de fin',
    annonce_form_valider_annonce: 'Valider',
    annonce_form_pas_de_logement: 'Aucun logement trouvé',
    annonce_form_pas_de_locataire:'Aucun habitant trouvé',
    annonce_form_creer_logement: 'Créer un logement',
    annonce_form_explication: 'Une annonce concerne un logement du coup avant de pouvoir en créer une, vous devez préalablemenet creér au moins un logement',
 
    //message du formulaire de bâtiment
    batiment_form_wizard_suivant: 'Suivant',
    batiment_form_wizard_precedent: 'Précédent',
    batiment_form_wizard_bouton_enregistrer: 'Enregistrer',
    batiment_form_wizard_tab_content_1_titre: 'Informations de base',
    batiment_form_wizard_tab_content_2_titre: 'Localisation',
    batiment_form_label_nom_batiment: 'Nom du bâtiment',
    batiment_form_label_nom_batiment_exemple: 'Ex: Bâtiment A',
    batiment_form_nom_batiment_obligatoire: 'Le nom du bâtiment est obligatoire',
    batiment_form_label_batiment_reference: 'Reférence',
    batiment_form_batiment_reference: 'La reférence du Bâtiment est obligatoire',
    batiment_form_label_batiment_photo: 'Photo',
    batiment_form_label_batiment_photo_placeholder: 'Ajouter la photo du bâtiment',
    batiment_form_label_batiment_photo_bottom_text: 'Déposez le fichier ici ou cliquez pour modifier',
    batiment_form_label_batiment_cite: 'Cité',
    batiment_form_label_batiment_cite_reference: 'Reférence',
    batiment_form_label_batiment_cite_reference_obligatoire: 'La reférence de la cité est obligatoire',
    batiment_form_label_batiment_label_cite_reference: 'Reférence de la cité',
    batiment_form_label_batiment_label_cite_reference_exemple: 'Ex: CDJ',
    batiment_form_label_batiment_nom_cite: 'Nom de la Cité',
    batiment_form_label_batiment_nom_cite_exemple: 'Ex: Cité de la joie',
    batiment_form_label_batiment_nom_cite_obligatoire: 'Le nom de la cité est obligatoire',
    batiment_form_label_batiment_ajouter_photo_cite: 'Ajouter la photo de la cité',
    batiment_form_label_action: 'Action',
    batiment_form_label_ajouter_element: 'Ajouter un élément',
    batiment_form_label_pays: 'Pays',
    batiment_form_label_pays_obligatoire: 'Vous devez selectionner un pays',
    batiment_form_label_ville: 'Ville',
    batiment_form_label_ville_obligatoire: 'Ville obligatoire',
    batiment_form_label_ville_placeholder: 'Ville Ex:Yaoundé',
    batiment_form_label_quartier: 'Quartier',
    batiment_form_label_quartier_placeholder: 'Quartier Ex:Nsam',
    batiment_form_label_quartier_obligatoire: 'Quartier obligatoire',
    batiment_form_latitude: 'Latitude',
    batiment_form_longitude: 'Longitude',


    //message du formulaire de logement
    logement_wizard_tab_content_1_titre: 'Informations sur le logement',
    logement_wizard_tab_content_2_titre: 'Photos du logement',
    logement_wizard_tab_content_3_titre: 'Localisation du logement',
    logement_wizard_tab_content_4_titre: 'Caractéristiques approfondies du logement',
    logement_reference_du_logement: 'Reférence du logement',
    logement_reference_du_logement_obligatoire: 'Ce champ est obligatoire',
    logement_type_logement: 'Type de logement',
    logement_categorie_associee: 'Catégorie associée',
    logement_description_logement: 'Ajouter une description à votre logement',
    logement_logement_associe_au_batiment: 'Votre logement est-il associé à un batiment ?',
    logement_choisir_batiment_concerne: 'Bien vouloir choisir le batiment concerné',
    logement_dragText: 'cliquer pour choisir des images',
    logement_browseText: 'Vous pouvez choisir jusqu\'à 5 images',
    logement_nombre_de_chambre: 'chambre',
    logement_nombre_de_cuisine: 'cuisine',
    logement_nombre_de_salon: 'salon',
    logement_nombre_de_douche: 'douche',
    logement_nombre_de_parking: 'parking',
    logement_autres_caracteristiques: 'Autre(s) caractéristique(s)',
    logement_autres_caracteristiques_placeholder: 'Ex: Sona',
    logement_autres_caracteristiques_quantite: 'Quantité Ex: 1',
    logement_form_fermer: 'Fermer',

    //message du fichier LoadCsv
    loadcsv_modal_title: 'Configurer votre fichier Modèle',
    loadcsv_label_batiment_associe: 'Batiment associé',
    loadcsv_label_categorie_associe: 'Catégorie associée',
    loadcsv_label_choisir_sous_type: 'Vous devez choisir au moins un sous-type',
    loadcsv_label_telecharger: 'Télécharger',

    //messages de la page gestion
    gestion_menu_mes_annonces: 'Mes annonces',
    gestion_menu_mes_occupations: 'Mes occupations',


    //messages de l'occupation
    occupation_creer_un_bail: 'Créer un bail',
    occupation_pas_de_occupation: 'Vous n\'avez défini aucune occupation pour le moment',
    occupation_occupations_disponibles: 'Occupations disponibles',
    occupation_bail_termine_le: 'Bail terminé le',
    occupation_bail_en_cours: 'Bail en cours',
    occupation_cloturer_bail:'Clôturer le bail',
    occupation_contrat_occupation: 'Contrat de l\'occupation',
    occupation_consulter_contrat: 'Consulter le contrat',
    occupation_editer_occupation: 'Editer l\'occupation',
    occupation_occupation_numero: 'Occupation n°',
    occupation_appercu: 'Aperçu',
    occupation_impayer_loyer: 'Impayés de loyer',
    occupation_impayer_lumiere: 'Impayés de lumière',
    occupation_impayer_eau: 'Impayés d\'eau',
    occupation_impayer_charges: 'Impayés de charges',
    occupation_total_impayer: 'Total des impayés',
    occupation_locataire: 'Locataire',
    occupation_numero_cni: 'Numéro de CNI',
    occupation_numero_telephone: 'Numéro de téléphone',
    occupation_email: 'Email',
    occupation_date_naissance: 'Date de naissance',
    occupation_voir_dossier: 'Voir le dossier',
    occupation_logement: 'Logement',
    occupation_voir_plage_loyer: 'Plage de loyer',
    occupation_localisation: 'Localisation',
    occupation_batiment: 'Bâtiment',
    occupation_cite: 'Cité',
    occupation_voir_details: 'Plus de détails',
    occupation_generalite: 'Généralités',
    occupation_loyers: 'Loyers',
    occupation_caution: 'Caution',
    occupation_charges: 'Charges',
    occupation_indexes: 'Indexes',
    occupation_comptes: 'Comptes',
    occupation_informations: 'Informations',
    occupation_informations_loyer: 'Loyer',
    occupation_informations_mode_paiement: 'Mode de paiement',
    occupation_informations_date_debut_bail: 'Date de début du bail',
    occupation_informations_date_fin_bail: 'Date de fin du bail',
    occupation_cree_le: 'Occupation créée le',
    occupation_duree_prevu_bail: 'Durée de bail prévue',
    occupation_caution_payee: 'Caution payée',
    occupation_facture_energie: 'Facturation d\'életricité',
    occupation_facture_eau: 'Facturation d\'eau',
    occupation_compte: 'Compte',
    occupation_creer_compte: 'Créer un compte',
    occupation_effectuer_recharge: 'Effectuer une recharge',
    occupation_indexes_eau: 'Indexes d\'eau',
    occupation_indexes_electricte: 'Indexes d\'électricité',
    occupation_mois: 'Mois',
    occupation_annee: 'Année',
    occupation_montant_a_payer: 'Montant à payer',
    occupation_montant_paye: 'Montant payé',
    occupation_observation: 'Observation',
    occupation_index_courant: 'Indexe courant',
    occupation_ancien_index: 'Ancien indexe',
    occupation_consommation1: 'Consommation ( en m3)',
    occupation_consommation2: 'Consommation ( en Kw)',
    occupation_consommation_non_consomme: 'Non consommé',
    occupation_consommation_non_payer: 'Impayé',
    occupation_montant: 'Montant',
    occupation_details: 'Détails',
    occupation_avance: 'Avance',
    occupation_avancer: 'Avancé',
    occupation_payer: 'Payé',
    occupation_a_payer: 'A payé',
    occupation_date_paiement: 'Date de paiement',
    occupation_reste: 'Reste',
    occupation_status: 'Status',
    occupation_tooltip_payer_maintenant: 'Payer maintenant',
    occupation_tooltip_completer_paiement: 'Completer le paiement',
    occupation_payer_le: 'Payé le',
    occupation_actif: 'Actif',
    occupation_terminer: 'Terminé',
    occupation_marquer_comme_terminer: 'Marquer comme terminée',
    occupation_activer_charge: 'Activer la charge',
    occupation_details: 'Détails',
    occupation_charge_payer: 'Payer',
    occupation_nature_charge: 'Nature de la charge',
    occupation_description_charge: 'Description',
    occupation_recharger_compte: 'Recharge de compte',
    occupation_recharger_compte_annuler: 'Annuler',
    occupation_compte_a_recharger: 'Compte à recharger',
    occupation_montant_de_la_recharge: 'Montant de la recharge',
    occupation_aucun_recu_paiement:'aucun recu de paiement rattaché à ce dépot !!',
    occupation_suppression_depot:'Supprimer le dépot',
    occupation_edition_depot:'Modifier',
    occupation_recu_paiement: 'recu de paiement', 
    occupation_montant_de_la_recharge_exemple: 'Ex: 5000',
    occupation_creer_compte_pour_occupation: 'Créer un compte à l\'occupation',
    occupation_creer_compte_label_compte: 'Label du compte',
    occupation_creer_compte_label_compte_exemple: 'Ex: Lumière',
    occupation_creer_compte_solde_initial: 'Solde initial',
    occupation_creer_compte_solde_initial_exemple: 'Ex: 5000',
    occupation_payer_charge_titre: 'Règlement de la charge',
    occupation_payer_charge_montant: 'Montant',
    occupation_payer_charge_description: 'Entrez le montant à préléver dans le compte',
    occupation_payer_charge_description_exemple: 'Ex: 5000',
    occupation_reglement_indexe_titre: 'Règlement de l\'indexe',
    occupation_reglement_loyer_titre: 'Règlement de loyer',
    
    //messages du contrat de l'occupation
    contratoccupation_aucun_contrat_pour_occupation: 'Aucun contrat rattaché à cette occupation',
    contratoccupation_imprimer: 'Imprimer',
    
    //messages du formulaire de l'occupation
    occupation_form_manque_logements:'Une occupation concerne un logement du coup avant de pouvoir en créer une, vous devez préalablemenet creér au moins un logement',
    occupation_form_manque_locataires:'Une occupation concerne un habitant du coup avant de pouvoir en créer une, vous devez préalablemenet creér au moins un habitant',
    occupation_form_ajouter_logement:'Ajouter un logement',
    occupation_form_ajouter_locataire:'Ajouter un habitant',
    occupation_form_wizard_tab_content_titre_1: 'Habitation',
    occupation_form_wizard_tab_content_titre_2: 'Facturation',
    occupation_form_wizard_tab_content_titre_3: 'Contrat',
    occupation_form_label_logement_de_occupation: 'Quel est le logement concerné par l\'occupation',
    occupation_form_label_logement_de_occupant: 'Qui est le résident pour lequel l\'occupation est défini',
    occupation_form_label_selectionner_date: 'Selectionnez une date',
    occupation_form_label_duree_du_bail: 'Durée du bail',
    occupation_form_label_duree_du_bail_exemple: 'Ex: 6',
    occupation_form_label_mettre_fin_au_bail_precedent: 'Mettre fin au bail précédent',
    occupation_form_label_combien_doit_payer_le_resident: 'Combien devra payer le résident',
    occupation_form_label_combien_doit_payer_le_resident_exemple: 'Ex: 45000',
    occupation_form_label_nombre_de_mois_avance: 'Nombre de mois d\'avance',
    occupation_form_label_nombre_de_mois_avance_description: 'Combien de mois le locataire a t-il avancé',
    occupation_form_label_caution: 'Caution',
    occupation_form_label_caution_description: 'somme versée faisant office de caution',
    occupation_form_label_indexe_initial: 'Index initial',
    occupation_form_label_indexe_initial_exemple: 'Ex: 095',
    occupation_form_label_indexe_initial_description: 'Index du compteur d\'eau à l\'entrée du locataire',
    occupation_form_label_indexe_electricite_initial_description: 'Index du compteur d\'électricité à l\'entrée du locataire',
    occupation_form_taux_forfaitaire: 'Taux forfaitaire',
    occupation_form_prix_unitaire_electricite: 'Prix unitaire de l\'électricité',
    occupation_form_prix_unitaire_electricite_exemple: 'Ex: 150',
    occupation_form_indexe_initial_electricite_exemple: 'Ex: 095',
    occupation_form_taux_forfaitaire_description: 'L\'exemple suggéré suppose une consommation par forfait',
    occupation_form_template_contrat_bail_location: 'template de contrat de bail ou de location',
    occupation_upgrade_formule: 'Impossible de créer un nouveau bail car vous avez atteint le budget de votre formule. Veuillez passer à une formule supérieure',
    occupation_upgrade:'Passer à une formule supérieure',
    occupation_cancel: 'Fermer',
    occupation_caution_montant_verse:'Montant Versé',

    //messages des locataires insolvables
    ionsolvable_pas_de_insolvable: 'Aucun locataire insolvable n\'a été trouvé',
    ionsolvable_en_eau: 'Eau',
    ionsolvable_en_lumiere: 'Lumière',
    ionsolvable_charges_insolvable: 'Charges',
    ionsolvable_total_insolvable: 'Total',
    ionsolvable_voir_dossier_locataire: 'Voir le dossier du locataire',

    //messages des locations
    locations_mes_habitants: 'Mes locataires',
    locations_Insolvables: 'Insolvables',

    //messages des préférences
    preferences_compte_bailleur: "Compte bailleur",
    preferences_accueil: "Bienvenu sur le Maisonier",
    preferences_accueil_explications: "vous avez la possibilité de configurer votre langue, votre dévise, et plus encore",
    preference_langue: 'Langue',
    preference_langue_anglais: 'Anglais',
    preference_langue_francais: 'Français',
    preference_devise: 'Devise',
    preference_devise_actuelle: 'Dévise actuelle',
    preference_change_langue: 'Changer la langue',
    preference_change_devise: 'Changer la dévise',
    preference_choisir_devise: 'Choisir la dévise',
    preference_choisir_langue: 'Choisir la langue',


    //messages de details de l'habitant

    detail_habitant_tooltip_solde_principal: 'Solde du compte principal',
    detail_habitant_impayer_loyer: 'Impayés en loyer',
    detail_habitant_impayer_eau: 'Impayés en eau',
    detail_habitant_impayer_electricite: 'Impayés en électricité',
    detail_habitant_impayer_charges: 'Impayés des charges',
    detail_habitant_total_impayer: 'Total des impayés',
    hatitant_detail_messages: 'messages',

    detail_habitant_occupations: 'Occupations',
    detail_habitant_ajouter_occupation: 'Ajouter une occupation',
    detail_habitant_numero: 'N°',
    detail_habitant_logement: 'Logement',
    detail_habitant_prix_loyer: 'Prix du Loyer',
    detail_habitant_facture_prepayer: 'Prépayé',
    detail_habitant_facture_postpayer: 'Postpayé',
    detail_habitant_facture_regler: 'réglé',
    detail_habitant_facture_non_regler: 'non réglé',
    detail_habitant_facture_tooltip_acceder_occupation: 'Accéder à l\'occupation',
    detail_habitant_pas_encore_publier: 'Pas encore publiée',
    detail_habitant_deja_publier: 'déja publié',
    detail_habitant_modifier: 'Modifier',
    detail_habitant_supprimer: 'Supprimer',
    detail_habitant_deja_recu: 'vous avez déjà reçu',
    detail_habitant_proposition: 'proposition',
    habitant_form_tab_content_titre_1: 'Données personnelles',
    habitant_form_tab_content_titre_2: 'Photo de profil',
    habitant_form_tab_content_titre_3: 'Autres informations',
    habitant_form_choix_mode_creation: 'Veuillez choisir le mode de création',
    habitant_form_choix_mode_creation_exemple: 'Ex: Veuillez entrer le code de l\'utilisateur',
    habitant_form_envoyer: 'Envoyer',
    habitant_form_nom_obligatoire: 'Nom Obligatoire',
    habitant_form_lieu_naissance: 'Lieu de Naissance',
    habitant_form_numero_telephone_exemple: 'Ex: 671202344',
    habitant_form_numero_telephone_obligatoire: 'Numéro de Téléphone obligatoire',
    habitant_form_email: 'Ex: tagnemiguel@gmail.com',
    habitant_form_titre_honorifique: 'Titre honorifique',
    habitant_form_autre_telephone: 'Autre numéro de télephone',
    habitant_form_autre_telephone_exemple: 'Ex: 671202344',
    habitant_form_autre_mail: 'Autre adresse mail',
    habitant_form_autre_numero_cni: 'Numéro Pièce D\'identité',
    habitant_form_autre_numero_cni_exemple: 'Ex: 114989895',
    habitant_form_ajouter_habitant: 'Ajouter un habitant',
    habitant_form_pas_de_habitant: 'Aucun habitant crée pour l\'instant',
    habitant_form_dossier_de: 'Dossier de ',
    locataire_detail_cni_recto:'CNI avant',
    locataire_detail_cni_verso:'CNI arrière',
    
    //messages de la liste des dépenses
    liste_depenses_mes_depenses: 'Mes depenses',
    liste_depenses_ajouter_une_depense: 'Ajouter une dépense',
    liste_depenses_pas_depenses: 'Aucune dépense enregistrée pour le moment',
    liste_depenses_details_depense: 'Détails de la dépense',

    //messages du formulaire de depense
    depense_form_label_montant: 'Montant',
    depense_form_label_montant_description: 'à combien s\'élève la dépense éffectuée?',
    depense_form_label_montant_exemple: 'Ex: 45000',
    depense_form_label_date_depense: 'Date à laquelle la dépense a été éffectuée',
    depense_form_label_motif: 'Motif',
    depense_form_label_motif_description: 'Pourquoi avez-vous éffectué cette dépense ?',
    depense_form_label_motif_description_exemple: 'Ex: Replacement ampoules cassées',
    depense_form_label_responsable: 'responsable',
    depense_form_label_responsable_description: 'Qui est celui qui a déclaré la dépense?',
    depense_form_label_observation_placeholder: 'C\'est ici que vou pouvez décrire ce qui a engendré la dépense',
    depense_form_label_joindre_une_photo: 'joindre une photo',
    depense_form_label_joindre_une_photo_bottom_text: 'déposez le fichier ici ou cliquez pour modifier',
    depense_form_label_joindre_une_photo_bottom_text_placeholder: 'joindre recu dépense',
    depense_form_label_votre_depense_concerne: 'Votre dépense concerne',
    depense_form_label_cite_concerne: 'Cité concernée',
    depense_form_label_batiment_concerne: 'Bâtiment concerné',
    depense_form_label_logement_concerne: 'Logement concerné',
    depense_form_non_traiter: 'Non traitée',
    depense_form_tooltip_montant_depense: 'Montant de la dépense',
    depense_form_tooltip_date_operation: 'Date de l\'opération',


    //messages de la page des articles
    article_nouvel: 'Nouvelle',
    article_numero_article: 'Numéro Article',
    article_details_article_rubrique: 'Rubrique(s)',
    article_details_article_ajouter_rubrique: 'Ajouter des rubriques',
    article_details_article_pas_de_rubrique: 'Aucune rubriques enregistré pour le moment',
    article_details_article_rubrique_description: 'description de la rubrique',
    article_details_article_rubrique_avoir_valeur: 'A t\'elle une valeur ?',
    article_details_article_rubrique_description_exemple: 'Ex: Ils ne pourront être utilisé même temporairement à d\'autres usages que celui ainsi arrêté sous peine de résiliation de plein droit de bail, suivi d\'un délaissement immédiat à la première demande du bailleur .',
    article_details_article_rubrique_avoir_valeur_oui: 'Oui',
    article_details_article_rubrique_avoir_valeur_non: 'Non',
    article_details_article_ajouter_element: 'Ajouter un élément',

    //messages de la page contrat
    contrat_image_alt: 'template de contrat de bail ou de location',

    //messages searchbar
    searchbar_placeholder: 'Rechercher',

    // messages du header
    header_profil: 'Profil entreprise',
    header_deconnexion: 'Se déconnecter',


    //messages de la page pagination
    pagination_affichage_de: 'Affichage de',
    pagination_de: 'de',
    pagination_elements: 'éléments',

    //messages de la page printer
    printer_definition_maisonier: 'Application de gestion immobilière',

    //messages de la page confirm
    slide_1_titre: 'Gérez facilement vos biens immobiliers',
    slide_1_description: 'Prenez votre envole et découvrez une nouvelle façon de gerer vos biens. Plus besoin d\'une assistance quelconque. Tout se fait en un clic.',
    slide_2_titre: 'Ne perdez plus aucune information',
    slide_2_description: 'Maisonnier rassemble, traite et stocke toutes vos informations et vous permet d\'utiliser votre memoire pour autre chose.',
    slide_3_titre: 'Optimisez la valeur de vos biens',
    slide_3_description: 'Decouvrez la rentabilité de vos biens, observez vos bénéfices en continue, interagissez avec vos locataires et bien plus encore...',
    confirm_consulter_mail: 'Veuillez consulter votre boîte mail pour avoir votre code d\'activation à 6 chiffres',
    confirm_pas_compte: 'Pas de compte?',
    confirm_creer_compte: 'Créer un compte maintenant',
    confirm_pas_recu_de_code: 'Je n\'ai pas reçu de code ?',
    confirm_reenvoyer_code: 'Ré-envoyer',
    confirm_adresse1: 'voir l\'adresse renseignée',
    confirm_adresse2: 'masquer l\'adresse',

    //messages de la page forgotPassword
    forgotPassword_retrouver_mdp: 'Retrouver votre mot de passe',
    forgotPassword_addresse_email: 'Adresse email',
    forgotPassword_code_verification: 'Code de verification',
    forgotPassword_nouveau_mdp: 'Nouveau mot de passe',
    forgotPassword_vous_avez_pas_de_compte: 'Vous n\'avez pas de compte ?',
    forgotPassword_inscription: 'Inscrivez-vous',

    //messages de la page login
    login_se_connecter: 'Se connecter',
    login_email_telephone: 'Email ou téléphone',
    login_mot_de_passe: 'Mot de passe',
    login_mot_de_passe_oublier: 'Mot de passe oublié ?',
    login_connexion: 'Connexion',
    login_fournir_une_addresse_email: 'Vous devez fournir une adresse mail',
    login_laisser_un_message: 'En quoi pouvons nous vous aider ?',

    //messages de la page subscribe
    subscribe_souscrire: 'Souscrire',

    //messages de la page regisiter
    register_creer_votre_compte: 'Créer votre compte',
    register_homme: 'Homme',
    register_femme: 'Femme',
    register_entreprise: 'Entreprise',
    register_vous_un_compte: 'Vous avez déjà un compte ?',
    register_connectez_vous: 'Connectez-vous',
    register_creer_un_compte: 'Créer un compte',


    //messages de la page du formulaire de contrat
    contrat_form_wizard_tab_content_titre_1: 'Ecriture du modèle',
    contrat_form_wizard_libelle_modele: 'Libellé du modèle',
    contrat_form_wizard_libelle_modele_exemple: 'Ex: Contrat de bail à usage d\'habitation',
    contrat_form_wizard_libelle_modele_obligatoire: 'Le libellé est obligatoire',
  
    //messages de la page de listing des contrats
    contrat_creer_un_modele_de_contrat: 'Créer un Modèle de Contrat',
    contrat_creer_un_modele_de_contrat_description: 'Un contrat de bail ou un contrat de location est le contrat par lequel l\'une des parties (appelée bailleur) s\'engage, moyennant un prix (le loyer) que l\'autre partie (appelée preneur) s\'oblige à payer, à procurer à celle-ci, pendant un certain temps, la jouissance d\'une chose mobilière ou immobilière.',
    contrat_pas_de_resultat: 'Aucun résultat trouvé',
    contrat_fermer: 'Fermer',

    //messages de la page de configuration
    configuration_contrats: 'Contrats',
    configuration_documentation: 'Documentation',

    //messages du formaulaire des Charges
    charge_form_montant: 'Montant',
    charge_form_montant_exemple: 'Ex: 3000',
    charge_form_montant_description: 'Combien voulez-vous attribuer ?',
    charge_form_montant_label_description: 'Description',
    charge_form_montant_label_description_explication: 'Pourquoi attribuez-vous cette charge ?',
    charge_form_type_de_charge: 'Type de charge concernée',
    charge_form_statut: 'Statut',

    //messages formulaire des Cautions
    caution_form_type_de_caution:'Type de caution',

    //messages du formaulaire des cautions
    caution_form_montant : 'Montant',

    //messages de la page des charges
    charge_description: 'Fiche de loyers',
    charge_pas_de_occupations: 'Vous n\'avez défini aucune occupation pour le moment',
    charge_loyer_de_base: 'Loyer de base',
    charge_consommation_en_eau: 'Consommation en eau',
    charge_consommation_en_lumiere: 'Consommation en lumière',
    charge_effectuer_un_paiement: 'Effectuer un paiement',
    charge_correspondance: 'Correspondance',
    charge_ajouter_paiement: 'Ajout de paiement',
    charge_effectuer_versement_pour: 'Effectuer un versement pour',
    charge_paiement_loyer: 'Paiement du loyer',
    charge_label_type_paiement_loyer: 'Type de paiement',
    charge_label_type_paiement_loyer_description: 'Par quel canal doit-on effectuer le paiement',
    charge_label_selectionner_methode: 'Selectionnez une méthode',
    charge_label_payer_en_espece: 'Payer en espèce',
    charge_label_payer_par_compte_lumiere: 'Payer à partir de compte lumière',
    charge_label_combien_doit_on_verser: 'Combien doit-on verser',
    charge_label_yaounde_le: 'Yaoundé le',
    charge_label_objet: 'Objet',
    charge_label_loyer_du_mois_de: 'Etat des loyers du mois de ',
    charge_label_vous_occuper_le_logement: 'vous occupez le logement',
    charge_label_vous_occuper_le_logement_situe_a: 'situé à ',
    charge_label_ci_dessous_le_loyer_du_mois: 'Ci-dessous, votre loyer du mois de',
  
    //messages de la page de recharge des comptes
    recharger_compte_en_masse: 'Recharge des comptes en masse',
    recharger_solde_du_compte: 'Solde du compte',
    recharger_modifier_le_solde: 'Modifier le solde',
    recharger_plusieurs_compte: 'Recharge Simultanée de plusieurs comptes',
    recharger_pas_de_occupation: 'Vous n\'avez crée aucune occupation pour le moment',
    recharger_solde_actuel: 'Solde Actuel',
    recharger_montant_recharge: 'Entrez le montant',
  

    //messages de la page de finance
    finance_indexes: 'Indexes',
    finance_recharges: 'Recharges',
    finance_cautions: 'Cautions',


    //messages de la page des indexes
    indexes_pas_de_indexes: 'Vous n\'avez enregistré aucun index pour le moment',
    indexes_du_mois_de: 'Indexes du mois de',
    indexes_eau: 'Eau',
    indexes_indexes_eau: 'Indexe eau',
    indexes_indexes_electricite: 'Indexe électricité',
    indexes_electricite: 'Electricité',
    indexes_entrez_nouveaux_indexes: 'Entrez les nouveaux indexes',
    indexes_mois_passe: 'Mois passé',
    indexes_popover_indexe_precedent: 'Indexes précedents',
    indexes_releves_indexes_du_mois_de:'Fiche de relevé d\'énergies du mois de ',
    indexes_consommation_indexes_du_mois_de:'Fiche de consommation en energies du mos de ',
  
    //messages de la page de loyers
    loyers_paiement_automatique: 'Lancer le paiement automatique',
    loyers_generer_charges: 'Générer les charges',
    loyers_generer_les_factures: 'Générer les factures',
    loyers_effectuer_versement_pour: 'Effectuer un versement pour',
    loyers_total_a_payer: 'Total à payer',
    loyers_total_a_payer_description: 'Ce prix soustrait les montants avancés par le locataire pour cette facture',
    loyers_montant_a_prelever: 'Montant à préléver',
    loyers_montant_a_prelever_description: 'Entrez le montant versé pour cette facture. Il sera débité du compte de l\'occupation',
    loyers_montant_net_a_payer: 'Net à payer',

    //messages page des préférences
    preferences_prix_unitaire_eau:'Prix unitaire de l\'eau',
    preferences_prix_unitaire_electricite:'Prix unitaire de l\'electricite',
    preferences_TVA: 'TVA',
    preferences_mes_preferences_actuelles: 'Mes préférences Actuelles',
    preferences_mise_a_jour: 'Mise à jour de préférences',
    preferences_certifier_mes_informations: 'Certifier mes informations',
    preferences_mes_informations: 'Mes informations',
    preferences_certifier_mon_compte: 'Certifier mon compte',
    preferences_photo_avant_cni: 'Photo avant CNI',
    preferences_photo_arriere_cni: 'Photo arrière CNI',

    cites: 'Cités',
    batiments: 'Bâtiments',
    logements: 'Logements',
    annonces: 'Annonces',
    reservations: 'Reservations',
    habitants: 'Habitants',
    insolvables: 'Insolvables',
    depenses: 'Dépenses',

    possessions_actuelle: "Vous possédez actuellement",
    total_insolvable: "Vous disposez actuellement de",
    avertissement: "Avertissement",
    avertissement_formule_inadequate: "formule inadéquate",
    avertissement_formule_inadequate_description: "Actuellement, vous utilisez une formule inadéquate à l'action que vous voulez mener. Cette fonctionnalité est disponible pour les comptes payants. Vous pouvez consulter notre page des",
    pour_plus_iunformations: "pour plus d'informations.",
    payer_formule_access: "payer la formule ACCESS",
    formule_utiilisation_du: "Formules d'utilisation du",
    utilisation_par_an: "an",
    souscrire_a_un_pack: "Souscrire",
    souscrire_au_forfait: "Souscription au forfait",
    souscrire_au_forfait_facturation: "FACTURATION",
    souscrire_au_forfait_type: 'TYPE',
    souscrire_au_forfait_moyen_paiement: "Méthode de paiement",
    souscrire_au_forfait_moyen_paiement_description: "Vous pouvez payer par MTN Mobile Money, Orange Money ou Paypal.",
    souscription_frequence: "FREQUENCE",
    profile_utilisateur: 'Profil utilisateur',
    occupation: 'Occupation',
    occupation_au_total: 'au total',
    occupation_cloturer_bail: 'Clôturer le bail',
    regler_impayer: 'Régler les impayés',
    repartition_des_impayes: "Répartition des Impayés",
    charges_en_eau: 'charges en eau',
    charges_en_electricite: 'charges en électricité',
    autres_charges: 'autres charges',
    solde_des_comptes: 'Solde des compte',
    solde: "Solde",
    impayes: 'Impayés',
    informations_personnelles: 'Informations personnelles',
    afficher_le_logement:"Afficher le logement",
    pas_de_facture_impaye: "Ce locataire n'a aucune facture impayée",
    nature: "Nature",
    periode: "Periode",
    suivi_des_index: "suivi des index",
    taux_forfaitaire: "Taux forfaitaire",
    paiement_lumiere: "Paiement de la lumière",
    paiement_eau: "Paiement de l'eau",
    paiement_eau_type_paiement: 'Type de paiement',
    paiement_eau_type_paiement_description: 'Par quel canal doit-on effectuer le paiement',
    selectionner_methode: "Selectionnez une méthode",
    payer_en_espece: "Payer en espèce",
    payer_a_partir_compte_eau: "Payer à partir de compte eau",
    canal_paiement: "Par quel canal doit-on effectuer le paiement",
    pu_eau: "P.U Eau",
    pu_lumiere: "P.U Electricité",
    forfait_eau: "Forfait Eau",
    forfait_lumiere: "Forfait Electricité",
    graphe: "Graphes",
    annonce: "Annonce",

    // message du formulaire de correspondance

    correspondance_envoyer_par_mail: 'Envoyer par mail',
    correspondance_envoyer_par_sms: 'Envoyer par sms',
    correspondance_envoyer_par_whatsapp: 'Envoyer par whatsapp',
    correspondance_envoyer_par_imprimer_facture: 'Imprimer la facture',
    imprimer_fiche_de_recette: 'Imprimer la fiche de recette',
    arrieres: 'Arriéres',
    energie: "Energie",
    total_impayes: "TOTAL IMPAYES",
    recettes: "Recettes",
    contacts: "Contacts",

    indexes_non_renseigner: "Non renseignés",
    indexes_deja_renseigner: "Déjà renseignés",
    indexes_renseigner_et_non: "Tous",
    indexes_pas_de_indexes: "Aucun indexe pour ce filtre",
    indexes_recap_consommation: "Recap de la conso de",
    indexes_ancien: 'Ancien',
    indexes_nouveau: 'Nouveau',
    indexes_consommation: 'Conso.',

    login_laissez_un_msg: "Laissez-nous un message",


    //messages page des utilisateurs

    user_form_explication: 'Un utilisateur est ajouté pour un but précis, du coup vous dévez prélablement ajouter les différentes attributions qu\'il peut avoir',
  
    //messages ribtique des roles
    role_details_retour:"Retour",

    reservations_en_cours: "Reservations en cours",
    reservations_en_attentes: "Reservations en attente",
    reservations_aprouvees: "Reservations aprouvées",
    reservations_rejetees: "Reservations rejetées",
    toutes_les_reservations: "Toutes les reservations",

  }