import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import unAuthorized from "../views/403.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: { layout: "default" },
  },
  {
    path: "*",
    name: "notfound",
    meta: { layout: "default" },
    component: () => import("@/views/error-pages/notfound.vue"),
  },
  {
    path: "/dashbord",
    name: "dashbord",
    meta: { layout: "default" },
    component: () => import("@/views/accueil/Dashbord.vue"),
  },
  { path: "/", redirect: { name: "home" } },
  {
    path: "/profil",
    name: "profil",
    meta: { layout: "default" },
    component: () => import("@/views/user/Profile.vue"),
  },

  /**
   * Authentification
   */
  {
    path: "/login",
    name: "login",
    meta: { layout: "empty", noAuth: true },
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/subscribe/momo",
    name: "subscribe/momo",
    meta: { layout: "empty", noAuth: true },
    component: () => import("../views/auth/MomoPaymentForm.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: { layout: "empty", noAuth: true },
    component: () => import("../views/auth/Register.vue"),
  },
  {
    path: "/confirm",
    name: "confirm",
    meta: { layout: "empty", noAuth: true },
    component: () => import("../views/auth/Confirm.vue"),
  },
  {
    path: "/subscribe",
    name: "subscribe",
    meta: { layout: "empty" },
    component: () => import("../views/auth/Subscribe1.vue"),
  },
  {
    path: "/check-souscription",
    name: "check-souscription",
    meta: { layout: "empty" },
    component: () => import("../views/auth/CheckSouscription.vue"),
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    meta: { layout: "empty", noAuth: true },
    component: unAuthorized,
  },
  {
    path: "/select-entreprise",
    name: "select-entreprise",
    meta: { layout: "empty" },
    component: () => import("../views/auth/SelectEntreprise.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: { layout: "empty", noAuth: true },
    component: () => import("../views/auth/ForgotPassword.vue"),
  },
  {
    path: "/test",
    name: "test",
    meta: { layout: "empty" },
    component: () => import("../views/test.vue"),
  },

  /**Profil */
  {
    path: "/profil-user",
    name: "profil",
    meta: { layout: "empty" },
    component: () => import("../views/profil/profil.vue"),
  },

  /**
   * Configuration
   */
  //  {
  //     path: '/gestion-utilisateurs',
  //     name: 'gestion-utilisateurs',
  //     meta: { layout: 'default' },
  //     component: () =>
  //         import ('@/views/gestion-utilisateurs/gestion-utlisateurs.vue'),
  //     children: [

  //         {
  //             path: 'users',
  //             name: 'users',
  //             meta: { layout: 'default' },
  //             component: () => import('@/views/gestion-utilisateurs/users/Users.vue')
  //         },
  //         {
  //             path: '/users/:id',
  //             name: 'details-user',
  //             meta: { layout: 'default' },
  //             component: () => import('@/views/gestion-utilisateurs/users/UserDetails.vue')
  //         },
  //         {
  //             path: 'roles',
  //             name: 'roles',
  //             meta: { layout: 'default' },
  //             component: () => import('@/views/gestion-utilisateurs/roles/Roles.vue')
  //         },
  //         {
  //             path: '/roles/:id',
  //             name: 'details-role',
  //             meta: { layout: 'default' },
  //             component: () => import('@/views/gestion-utilisateurs/roles/DetailsRole.vue')
  //         },
  //         {
  //             path: 'authorisations',
  //             name: 'authorisations',
  //             meta: { layout: 'default' },
  //             component: () => import('@/views/gestion-utilisateurs/permissions/Authorisations.vue')
  //         }
  //     ]
  // },

  /**
   * Patrimoine immobilier
   */
  {
    path: "/patrimoine",
    name: "patrimoine",
    meta: { layout: "default" },
    component: () => import("@/views/patrimoine/patrimoine.vue"),
    children: [
      {
        path: "batiments",
        name: "batiments",
        meta: { layout: "default" },
        component: () => import("@/views/patrimoine/Batiments.vue"),
      },
      {
        path: "cites",
        name: "cites",
        meta: { layout: "default" },
        component: () => import("@/views/patrimoine/Cites.vue"),
      },
      {
        path: "logements",
        name: "logements",
        meta: { layout: "default" },
        component: () => import("@/views/logements/ListLogements.vue"),
      },
    ],
  },

  /**
   * Gestion immobiliere
   */
  {
    path: "/gestion",
    name: "gestion",
    meta: { layout: "default" },
    component: () => import("@/views/gestion-immobiliere/gestion.vue"),
    children: [
      // {
      //     path: 'occupations',
      //     name: 'occupations',
      //     meta: { layout: 'default' },
      //     component: () =>
      //         import ('@/views/gestion-immobiliere/Occupations.vue')
      // },
      // {
      //     path: 'occupations/:id',
      //     name: 'occupation',
      //     meta: { layout: 'default' },
      //     component: () =>
      //         import ('@/views/gestion-immobiliere/Occupations.vue')
      // },
      {
        path: "communique",
        name: "communique",
        meta: { layout: "default" },
        component: () => import("@/views/gestion-immobiliere/Communique.vue"),
      },
      {
        path: "messages",
        name: "messages",
        meta: { layout: "default" },
        component: () => import("@/views/gestion-immobiliere/Messages.vue"),
      },
    ],
  },
  {
    path: "/occupations/:id",
    name: "details-occupation",
    meta: { layout: "default" },
    component: () =>
      import("@/views/gestion-immobiliere/DetailsOccupations.vue"),
  },

  /**
   * Promotion immobiliere
   */
  {
    path: "/promotion",
    name: "promotion",
    meta: { layout: "default" },
    component: () => import("@/views/promotion-immobiliere/promotion.vue"),
    children: [
      {
        path: "annonces",
        name: "annonces",
        meta: { layout: "default" },
        component: () =>
          import("@/views/promotion-immobiliere/annonces/Annonces.vue"),
      },
      {
        path: "discussions",
        name: "discussions",
        meta: { layout: "default" },
        component: () =>
          import("@/views/promotion-immobiliere/Discussions.vue"),
      },
      {
        path: "reservations",
        name: "reservations",
        meta: { layout: "default" },
        component: () =>
          import("@/views/promotion-immobiliere/reservations/reservations.vue"),
      },
    ],
  },

  /**
   * configuration et aide
   */
  {
    path: "/configuration",
    name: "configuration",
    meta: { layout: "default" },
    component: () => import("@/views/configuration-aide/configuration.vue"),
    children: [
      {
        path: "doc",
        name: "doc",
        meta: { layout: "default" },
        component: () => import("@/views/configuration-aide/Doc/Doc.vue"),
      },
    ],
  },

  /**
   * Analyse & stats, depenses, notifications
   */
  {
    path: "/notifications",
    name: "notifications",
    meta: { layout: "default" },
    component: () => import("@/views/notifications/Notifications.vue"),
  },

  /**
   * Finances
   */
  {
    path: "/finances",
    name: "finances",
    meta: { layout: "default" },
    component: () => import("@/views/finances/finances.vue"),
    children: [
      {
        path: "indexes",
        name: "indexes",
        meta: { layout: "default" },
        component: () => import("@/views/finances/Indexes.vue"),
      },
      {
        path: "loyers",
        name: "loyers",
        meta: { layout: "default" },
        component: () => import("@/views/finances/Loyers.vue"),
      },
      {
        path: "/depenses",
        name: "depenses",
        meta: { layout: "default" },
        component: () =>
          import("@/views/gestion-immobiliere/depenses/ListDepenses.vue"),
      },
      {
        path: "recharges",
        name: "recharges",
        meta: { layout: "default" },
        component: () => import("@/views/finances/recharges/Recharges.vue"),
      },
      {
        path: "recettes",
        name: "recettes",
        meta: { layout: "default" },
        component: () => import("@/views/finances/Depots.vue"),
      },
      {
        path: "cautions",
        name: "cautions",
        meta: { layout: "default" },
        component: () => import("@/views/finances/cautions/Cautions.vue"),
      },
    ],
  },
  /**
   * Préférences
   */
  {
    path: "/parametres",
    name: "parametres",
    meta: { layout: "default" },
    component: () => import("@/views/parametres/parametres.vue"),
    children: [
      /*{
                path: 'preferences',
                name: 'preferences',
                component: () => import('@/views/parametres/preferences.vue')
            },*/
      {
        path: "preferences",
        name: "preferences",
        meta: { layout: "default" },
        component: () => import("@/views/parametres/NewParametres.vue"),
      },
      {
        path: "packs",
        name: "packs",
        meta: { layout: "default" },
        component: () => import("@/views/parametres/Packs.vue"),
      },
      {
        path: "users",
        name: "users",
        meta: { layout: "default" },
        component: () => import("@/views/gestion-utilisateurs/users/Users.vue"),
      },
      {
        path: "/users/:id",
        name: "details-user",
        meta: { layout: "default" },
        component: () =>
          import("@/views/gestion-utilisateurs/users/UserDetails.vue"),
      },
      {
        path: "roles",
        name: "roles",
        meta: { layout: "default" },
        component: () => import("@/views/gestion-utilisateurs/roles/Roles.vue"),
      },
      {
        path: "/roles/:id",
        name: "details-role",
        meta: { layout: "default" },
        component: () =>
          import("@/views/gestion-utilisateurs/roles/DetailsRole.vue"),
      },
      {
        path: "authorisations",
        name: "authorisations",
        meta: { layout: "default" },
        component: () =>
          import("@/views/gestion-utilisateurs/permissions/Authorisations.vue"),
      },
    ],
  },

  /**
   * Locations
   */
  //  {
  //     path: '/locations',
  //     name: 'locations',
  //     meta: { layout: 'default' },
  //     component: () =>
  //         import ('@/views/locations/locations.vue'),
  //         children : [
  //             {
  //                 path: 'insolvables',
  //                 name: 'insolvables',
  //                 meta: { layout: 'default' },
  //                 component: () =>
  //                     import ('@/views/locations/Insolvables.vue')
  //             },
  //             {
  //                 path: 'habitants',
  //                 name: 'habitants',
  //                 meta: { layout: 'default' },
  //                 component: () =>
  //                     import ('@/views/gestion-immobiliere/habitants/Habitants.vue')
  //             }
  //         ]
  // },

  /**
   * Gestion locative
   */
  {
    path: "/gestion-locative",
    name: "gestion-locative",
    meta: { layout: "default" },
    component: () => import("@/views/gestion-locative/gestion-locative.vue"),
    children: [
      {
        path: "habitants",
        name: "habitants",
        meta: { layout: "default" },
        component: () =>
          import("@/views/gestion-immobiliere/habitants/Habitants.vue"),
      },
      {
        path: "occupations",
        name: "occupations",
        meta: { layout: "default" },
        component: () => import("@/views/gestion-immobiliere/Occupations.vue"),
      },
      {
        path: "occupations/:id",
        name: "occupation",
        meta: { layout: "default" },
        component: () => import("@/views/gestion-immobiliere/Occupations.vue"),
      },
    ],
  },
  /**
   * Modèle de fichier
   */
  {
    path: "/modele-fichier",
    name: "modele-fichier",
    meta: { layout: "default" },
    component: () => import("@/views/modele-fichier/modele-fichier.vue"),
    children: [
      {
        path: "contrats",
        name: "contrats",
        meta: { layout: "default" },
        component: () =>
          import("@/views/configuration-aide/Contrats/Contrats.vue"),
      },

      {
        path: "pventre",
        name: "pventre",
        meta: { layout: "default" },
        component: () => import("@/views/modele-fichier/pv-entre.vue"),
      },
      {
        path: "pvsortie",
        name: "pvsortie",
        meta: { layout: "default" },
        component: () => import("@/views/modele-fichier/pv-sortie.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const php = require("phpjs");

router.beforeEach(async (to, from, next) => {
  const access_token = storage.get("access_token");

  //A Logged-in user can't go to login page again
  if (
    to.name === "login" &&
    access_token != null &&
    access_token != undefined &&
    access_token != ""
  ) {
    next({ name: "home" });
  } else if (
    to.matched.some(
      (record) => record.meta.noAuth && true === record.meta.noAuth
    )
  ) {
    next();
  } else if (!access_token || access_token == null || access_token == "") {
    store.dispatch("user", {});
    next({ name: "login" });
  } else {
    let user = store.state.user;
    if (php.empty(user.idUtilisateur)) {
      try {
        user = await axios
          .get(`utilisateurs/${access_token}`)
          .then((response) => response.result);
        store.dispatch("user", user);
      } catch (error) {
        store.dispatch("user", {});
        next({ name: "login" });
      }
    }

    if (to.name !== "select-entreprise" && php.empty(user.entreprises)) {
      next({ name: "select-entreprise", query: { reason: "no-enterprise" } });
    } else if (user.entreprises.length == 1) {
      const entreprise = user.entreprises[0];
      storage.set("selected_enterprise", entreprise.idEntreprise);
    }

    if (
      to.name !== "select-entreprise" &&
      php.empty(storage.get("selected_enterprise"))
    ) {
      next({ name: "select-entreprise", query: { reason: "more-enterprise" } });
    }

    if (isUnRedirect(to.name)) {
      next();
    } else {
      const entreprise = user.entreprises.find(
        (elt) => elt.idEntreprise == storage.get("selected_enterprise")
      );
      if (php.empty(entreprise)) {
        next({
          name: "select-entreprise",
          query: { reason: "no-selected-enterprise" },
        });
      } else if (php.empty(entreprise.abonnement)) {
        if (entreprise.Employes.isAdmin) {
          next({ name: "subscribe" });
        } else {
          next({
            name: "select-entreprise",
            query: { reason: "no-formule-set" },
          });
        }
      } else {
        let privileges = [];
        for (const role of entreprise.Employes.roles) {
          privileges = [...privileges, ...role.privileges];
        }
        store.dispatch("permissions", privileges);

        if (!store.getters.hasAccess(to.name)) {
          next({ name: "unauthorized" });
        } else {
          next();
        }
      }
    }
  }
});

const isUnRedirect = (name) => {
  return ["select-entreprise", "subscribe", "check-souscription"].includes(
    name
  );
};

export default router;
