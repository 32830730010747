export default function (name, permissions) {
    switch (name) {
        case "home":
        case 'select-entreprise':
        case 'subscribe':
        case 'profil':
            return true;

        case "dashbord":
            return permissions.includes("view_tableau_de_bord")

            //menu configurations 
        case "gestion-utilisateurs":
            return permissions.includes("manage_users")
        case "roles":
            return permissions.includes("list_roles")
        case "details-role":
            return permissions.includes("list_roles")
        case "authorisations":
            return permissions.includes("list_authorizations")

        case "users":
            return permissions.includes("list_users")
        case "details-user":
            return permissions.includes("list_users")

            //menu patrimoine-immobilier
        case "patrimoine":
            return permissions.includes("manage_patrimoine")

        case "cites":
            return permissions.includes("list_cites")

        case "batiments":
            return permissions.includes("list_batiments")

        case "logements":
            return permissions.includes("list_logements")

            //menu gestion-immobilière
        case "gestion":
            return permissions.includes("manage_gestion_immobiliere")
        case "occupations":
            return permissions.includes("list_occupations")

        case "details-occupation":
            return permissions.includes("list_occupations")
        case "annonces":
            return permissions.includes("list_annonces")

        case "reservations":
            return permissions.includes("list_reservations")

            //menu locations
        case "locations":
            return permissions.includes("manage_locations")

        case "habitants":
            return permissions.includes("list_habitants")

        case "insolvables":
            return permissions.includes("list_insolvables")

            //menu dépenses
        case "depenses":
            return permissions.includes("list_depenses")

            //menu finances
        case "finances":
            return permissions.includes("manage_finances")

        case "loyers":
            return permissions.includes("list_loyers")
        case "recettes":
            return permissions.includes("list_recettes")
        case "recharges":
            return permissions.includes("list_recharges")
        case "cautions":
            return permissions.includes("list_cautions")
        case "indexes":
            return permissions.includes("list_indexes")

            //menu configuration & aide
        case "configuration":
            return permissions.includes("manage_configuration_et_aide")

        case "contrats":
            return permissions.includes("list_modeles_contrats")
        case "doc":
            return permissions.includes("doc")

            //menu paramètres  
        case "parametres":
            return permissions.includes("manage_parametres")
        case "preferences":
            return permissions.includes("list_preferences")
        case "packs":
            return permissions.includes("formules_utilisation")
            
        default:
            return false;
    }
}