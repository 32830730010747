import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'

import locale from './modules/locale'
import hasPermit from '../helpers/permissions'

Vue.use(Vuex);

const state = {
    user: {
        avatar: null,
        nom: null,
        devise:null,
        profil: {}
    },
    permissions: [],
    notifications: [],

    
    userObject:{
        isAdmin:null,
        roles:[],
        privileges:[]
    },
    typesLogements:[],
    sousTypesLogements:[]
}

export default new Vuex.Store({
    modules: {
        locale
      },
      plugins: [createPersistedState()],
    state,
    getters: {
        user(state) {
            return state.user
        },
        userObject(state) {
            return state.userObject
        },
        permissions(state) {
            return state.permissions
        },
        notifications(state) {
            return state.notifications
        },
        isAdminUser(state) {
            const entreprise = (state.user.entreprises || []).find(elt => elt.idEntreprise == storage.get('selected_enterprise'))
            if (!entreprise) {
                return false
            }
            return entreprise.Employes.isAdmin == true
        },

        hasAccess(state, getters) {
            return (name) => {
                if (getters.isAdminUser) {
                    return true
                }
                return hasPermit(name, state.permissions)
            }
        }
    },
    mutations: {
        /*mutations liées à l'utilisateur */
        user_data(state, user) {
            state.user.nom = user.nom
            state.user.prenom = user.prenom
            state.user.email = user.email
            state.user.tel = user.tel
        },
        user(state, user) {
            state.user = user
        },
        userObject(state, result) {
            state.userObject = result;
            state.userObject.isAdmin = result.isAdmin;
            //state.permissions.privileges=result.privileges;  
        },
        permissions(state, permissions) {
            state.permissions = permissions
        },
        notifications(state, notifications) {
            state.notifications = notifications
        },

        /*mutations liées aux types et sous-types de logements */
        typesLogements(state, typesLogements){
            state.typesLogements=typesLogements;
        },
        sousTypesLogements(state, sousTypesLogements)
        {
            state.sousTypesLogements =sousTypesLogements;
        }   
     },
    actions: {
        user(context, user) {
            context.commit('user', user)
        },
        userObject(context, permissions) {
            // context.commit('permissions', permissions)
        },
        permissions(context, permissions) {
            context.commit('permissions', permissions)
        },
        notifications(context, notifications) {
            context.commit('notifications', notifications)
        },

        setTypesLogements(context, typesLogements){
            context.commit('typesLogements', typesLogements)
        },
        setSousTypesLogements(context, soustypesLogements){
            context.commit('sousTypesLogements', soustypesLogements)
        },
        setUserPreferences({ commit }){
            axios.get('utilisateurs/'+storage.get('access_token'))
                .then(response => {
                    storage.set('user', response.result)
                    commit('user', response.result)
            })
        },
        updateUserData({ commit }){
            let permisssions=[]
            axios.get('entreprises/utilisateurs/'+storage.get('user').idUtilisateur)
                .then(response => {
                    commit('userObject', response.result)
                    if(response.result.roles.length > 0){
                        for(let i=0; i<response.result.roles.length; i++){
                            for(let j=0; j<response.result.roles[i].privileges.length; j++){
                                permisssions.push(response.result.roles[i].privileges[j]);
                            }
                        }
                    }
                    storage.set('userObject', response.result)
                    storage.set('permissions', permisssions)
                    //window.location.href = this.$router.options.base
                    //window.location.reload()
                    /*storage.set('permissions2', response.result)
                    commit('permission', response.result)*/
            })
        },
        LogOut({commit}){
            let user = null
            commit('LogOut', user)
        }
    },
});