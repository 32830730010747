<template>
	<b-modal hide-header hide-footer v-model="shown" no-close-on-backdrop no-close-on-esc>
		<div class="d-flex align-items-center">
			<b-img src="@/assets/img/logo-m0.png" style="width: 4em;" />
			<h3 class="slogan ml-1 ">Maisonier Gestion</h3>
		</div>
		<p>
			Installer "Maisonier Gestion" sur votre {{ equipement }} pour une meilleure gestion de votre activité
		</p>
		<hr/>
		<div class="d-flex w-100 align-items-center">
			<b-button variant="danger" @click="installPWA" class="mr-1">Installer !</b-button>
			<b-button variant="secondary" @click="dismissPrompt">Non, merci</b-button>
		</div>
	</b-modal>
</template>
  
<script>
const ua = UAParser()
const php = require("phpjs")

export default {
    data: () => ({
      shown: false,
    }),
	
	computed: {
		equipement() {
			const equipement = []
			if (!php.empty(ua.device.type)) {
				equipement.push(ua.device.type)
			}
			if (!php.empty(ua.device.vendor)) {
				equipement.push(ua.device.vendor)
			}

			if (php.empty(equipement)) {
				return ua.os.name
			}

			return `${equipement.join(' ')} (${ua.os.name})` 
		}
	},

    beforeMount() {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.installEvent = e
        this.shown = true
      })
    },
  
    methods: {
      	dismissPrompt() {
        	this.shown = false
      	},
  
      	installPWA() {
        	this.installEvent.prompt()
        	this.installEvent.userChoice.then((choice) => {
          		this.dismissPrompt() // Hide the prompt once the user's clicked
          		if (choice.outcome === 'accepted') {
            		// Do something additional if the user chose to install
         	 	} else {
            		// Do something additional if the user declined
          		}
        	})
      	},
    }
}
 </script>

<style scoped>
.slogan {
	text-align: left;
	width: auto;
	color: #020202;
	font-size: 1.9rem;
	font-weight: bold;
}
.slogan::first-letter {
	font-size: 1.8rem;
	font-weight: 700;
}
</style>