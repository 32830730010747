<template>
	<component :is="layout">
    <pwa-prompt />
    <vue-page-transition name="fade-in-right">
      <router-view/>
    </vue-page-transition>
	</component>
</template>

<script>
const DEFAULT_LAYOUT = 'empty'

import Vue from 'vue'

import VuePageTransition from 'vue-page-transition'
import PwaPrompt from './components/PwaPrompt.vue'

Vue.use(VuePageTransition)

export default {
  name: 'App',
  components: {
    PwaPrompt,
  },
  computed: {
	  layout() {
		  return (this.$route.meta.layout || DEFAULT_LAYOUT) + '-layout'
	  }
  }
}
</script>
<style >
.vue-notification{
    padding: 10px;
  margin: 0 5px 5px;
 
  font-size: 12px;
 
  color: #ffffff;
  background: #68CD86;
    border-left-color: #42A85F;
}
  .error{
   background: #68CD86;
    border-left-color: #42A85F;
  }
</style>