<template>
    <div class="">
		<b-container fluid class="p-5">
			<h1 class="font-weight-bold text-center text-uppercase"> <span class="text-danger text-uppercase">accès refusé</span></h1> 
			<hr/><br/>
			<div class="w-50 mx-auto">
				<b-alert show variant="info">
					<div class="h5">
						<p class="text-center h5 ">
							<i class="fa fa-ban fa-3x"></i> <br />
							Vous ne disposez pas du droit de consulter cette page. Pour résoudre ce problème vous avez deux solutions:
						</p>
						<ul>
							<li class="my-4">Vous contactez votre administrateur.</li>
							<li class="my-4">Vous créez votre porpre entréprise et  vous souscrivez à une formule d'utilisation.</li>
						</ul>
						<div class="d-flex justify-content-center"><b-button variant="danger" class="d-flex justify-content-between" @click.prevent="goHome"><i class="fa fa-arrow-left fa-2x mr-2"></i><span>retourner à l'accueil</span></b-button></div>
					</div>
				</b-alert>
			</div>
		</b-container>

		<b-button  class="position-fixed validate btn-icon" variant="danger" @click.prevent="logout" v-b-tooltip.left="'Se deconnecter'"><i class="fa fa-power-off fa-lg"></i></b-button>


	</div>
</template>

<script>
import {mapGetters} from 'vuex'
const php  = require ( 'phpjs' )

export default {
    name: 'subscribe',
    data: () => ({


    }),
	computed: {
		...mapGetters(['user']),

	},
    mounted() {
    },
    

    methods: {
		logout() {
           	this.$store.dispatch('user', {})
            storage.clear()
            this.$router.push({name: 'login'})
        },
		goHome(){
			this.$router.push({name: 'home'})
		}

	}
}
</script>


<style scoped>
button.validate{
    bottom: 1em;
    right: 1em;
    width: 3em;
    height: 3em;
    box-shadow: 3px 1px 1px gray;
}
</style>