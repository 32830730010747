import Vue from 'vue'
import store from '../store'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import { baseUrl } from '../helpers/utils';

Vue.use(new VueSocketIO({
    debug: true,
    connection: SocketIO(baseUrl().replace('http', 'ws')), //options object is Optional
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  })
);