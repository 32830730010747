<template>
    <div id="app" class="wrapper">
        <app-header />
        <div class="page-wrap">
            <sidebar />
            <div class="main-content">
                <div class="container-fluid">
                    <slot/>
                </div>
            </div>
            <full-features />
            <AppFooter />
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/templates/Header.vue'
import Sidebar from '@/components/templates/Sidebar.vue'
import AppFooter from '@/components/templates/Footer.vue'
import FullFeatures from '@/components/templates/FullFeatures.vue'

import Vue from 'vue'

import PageDescription from '@/components/templates/PageDescription.vue'
import BtnAdd from '@/components/templates/btn.vue'
import Paginator from '@/components/Paginator.vue'
import PaginatorModeMultiUser from '@/components/PaginatorModeMultiUser.vue'
import PaginatorTop from '@/components/PaginatorTop.vue'
Vue.component('PageDescription', PageDescription)
Vue.component('BtnAdd', BtnAdd)
Vue.component('Paginator', Paginator)
Vue.component('PaginatorTop', PaginatorTop)
Vue.component('Paginator2', PaginatorModeMultiUser)

import VueRepeater from 'vue-repeater'
import 'vue-repeater/dist/lib/vue-repeater.css'
Vue.component('vue-repeater', VueRepeater)

import FileSelector from 'vue-file-selector';
import { mapGetters } from 'vuex'
Vue.use(FileSelector);

window.Notification = window.webkitNotification || window.mozNotification || window.Notification

export default {
    name: 'DefaultLayout',
    computed:{
        ...mapGetters(["user"]),
    },
    components: {
        AppHeader, 
        Sidebar,
        AppFooter,
        FullFeatures
    },
    sockets: {
        connect: function () {
            console.log('socket connected')
        },
        notif(data) {
            if (data.idEntreprise == storage.get("selected_enterprise") && data.idUtilisateur != this.user.idUtilisateur) {
                axios.get('notifications').then(response => response.result || []).then(result => {
                    this.$store.dispatch("notifications", result);
                    const audio = new Audio('https://soundbible.com/mp3/sms-alert-3-daniel_simon.mp3');
                    audio.muted = false;
                    audio.volume = 1.0;
                    audio.play();
                })
                /* window.Notification.requestPermission(status => {
                    if (status == 'granted') {
                        let msg = new window.Notification(data.libelle, data)
                        msg.onclick = () => {
                            window.location.href = data.url
                        }
                    }
                }) */
            }
            
            // console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
        }
    },
    mounted() {
        initTemplateScript(window, document, jQuery)
        this.activeSidebarItem()
    },
    updated() {
		this.activeSidebarItem()
	},
	methods: {
		/**
		 * Marque un item de la sidebar comme etant actif
		 */
		activeSidebarItem() {
			$('#main-menu-navigation *').removeClass('active')
			$('#main-menu-navigation .router-link-exact-active').parent('.nav-item').addClass('active')
		}, 
	}
}
</script>