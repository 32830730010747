<template>
    <div>
        <link rel="stylesheet" href="">
         <b-overlay :show="showOverlay" rounded="sm">   
            <div class="top-banner">
                <div class="img" :style="`background:url(${$router.options.base}img/appart.jpg)`">
                    <div class="bg"></div>
                    <div class="txt" style="display:none">
                        <h1> {{ $t('data.home_bienvenu') }}</h1>
                        <p> {{ $t('data.home_paragraphe_informatif1') }} <br>{{ $t('data.home_paragraphe_informatif2') }} <b>{{ pourcentage }}%</b></p>
                        <button @click.prevent="gotToProfile" v-if="compteur!=0"> {{ $t('data.home_terminer_profil') }}</button>
                    </div>
                </div>
                <div class="summary">
                    <div class="stat">
                        <div class="stat-item">
                            <i class="fa fa-building"></i>
                            <div>
                                <h3> {{ $t('data.home_batiments') }} </h3>
                                <span v-if="nbrBatiments ==0"> {{ $t('data.home_aucun_batiment') }}</span>
                                <span v-else>{{ nbrBatiments }} {{ $t('data.home_batiments_crees') }}<span v-if="nbrBatiments>1">s</span></span>
                            </div>
                        </div>
                        <div class="stat-item">
                            <i class="fas fa-home"></i>
                            <div>
                                <h3> {{ $t('data.home_logements') }} </h3>
                                <span v-if="nbrLogements ==0"> {{ $t('data.home_pas_de_logements') }} </span>
                                <span v-else>{{ nbrLogements }} {{ $t('data.home_logements_crees') }}<span v-if="nbrLogements>1">s</span></span>
                            </div>
                        </div>
                        <div class="stat-item">
                            <i class="fas fa-users"></i>
                            <div>
                                <h3> {{ $t('data.home_habitants') }} </h3>
                                <span v-if="nbrLocataires ==0"> {{ $t('data.home_pas_dhabitants') }} </span>
                                <span v-else>{{ nbrLocataires }} {{ $t('data.home_locataires_crees') }}<span v-if="nbrLocataires>1">s</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
         </b-overlay> 
        <div class="action-container">
            <span> {{ $t('data.home_actions_rapides') }} </span>
            <div>
                <div class="action-item">
                    <div class="img">
                        <img src="@/assets/img/camp2.jpg" alt="">
                    </div>
                    <div class="txt">
                        <h2> {{ $t('data.home_creer_une_cite') }} </h2>
                        <p> {{ $t('data.home_descripton_cite') }} </p>
                        <button @click.prevent="createCite"> {{ $t('data.home_creer') }} </button>
                    </div>
                </div>
                <div class="action-item">
                    <div class="img">
                        <img src="@/assets/img/patrimoine2.jpg" alt="">
                    </div>
                    <div class="txt">
                        <h2> {{ $t('data.home_creer_batiment') }} </h2>
                        <p> {{ $t('data.home_description_batiment') }}</p>
                        <button @click.prevent="createBatiment"> {{ $t('data.home_creer') }}</button>
                    </div>
                </div>
                <div class="action-item">
                    <div class="img">
                        <img src="@/assets/img/appart-sejour (2).jpg" alt="">
                    </div>
                    <div class="txt">
                        <h2> {{ $t('data.home_creer_logement') }} </h2>
                        <p> {{ $t('data.home_description_logement') }} </p>
                        <button @click.prevent="createLogement"> {{ $t('data.home_creer') }} </button>
                    </div>
                </div>
                <div class="action-item">
                    <div class="img">
                        <img src="@/assets/img/loc.jpg" alt="">
                    </div>
                    <div class="txt">
                        <h2> {{ $t('data.home_creer_habitant') }} </h2>
                        <p> {{ $t('data.home_description_habitant') }}</p>
                        <button @click.prevent="createHabitant"> {{ $t('data.home_creer') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <notifications group="foo"  position="bottom right"  width="400"/>
    </div>
</template>

<script>

export default {
    name: 'Home',

    data: () => ({
        pourcentage:15,
        disabled: true,
        showOverlay: true,
        nbrLogements:0,
        nbrLocataires:0,
        nbrBatiments:0,
        compteur:0
    }),
    computed: {
        userData() {
            return this.$store.state.user
        },
    },
    mounted() {
        setTimeout(async() => {
            $('button.nav-toggle').click()
        }, 100);
        if(!this.userData.nom){this.compteur++}
            if(!this.userData.prenom){this.compteur++}
            if(!this.userData.avatar){this.compteur++}
            if(!this.userData.email){this.compteur++}
            if(!this.userData.tel){this.compteur++}
                if(!this.userData.genre){this.compteur++}
            if(!this.userData.dateNaiss){this.compteur++}
            if(!this.userData.profil.cni){this.compteur++}
            if(!this.userData.profil.titre){this.compteur++}
            if(!this.userData.profil.profession){this.compteur++}
            if(this.compteur>6){
                this.pourcentage=15
            }
            if(this.compteur>=4&&this.compteur<6){
                this.pourcentage=50
            }
            if(this.compteur<3){
                this.pourcentage=75
            }
                if(this.compteur==0){
                this.pourcentage=100
        } 
        this.$notify({
            group: 'foo',
            title: 'Important message',
            text: 'Tagne Miguel ajouté une nouvelle cité',
            type: 'success',
            duration: 1000
            
        });
        this.$notify({
            group: 'foo',
            title: 'Important message',
            text: 'votre abonnement prend fin le 12 Janvier 2022',
            type: 'warn'
            
        });
    },

    methods:{
        createCite() {
            this.$router.push({name: 'cites', query: {formCite: "call-cite"}})
        },
        createBatiment(){
           this.$router.push({name: 'batiments', query: {formBatiment: "call-batiment"}}) 
        },
        createLogement(){
            this.$router.push({name: 'logements', query: {formLogement: "call-logement"}}) 
        },
        createHabitant(){
            this.$router.push({name: 'habitants', query: {formHabitant: "call-habitant"}}) 
        },
        gotToProfile(){
            this.$router.push({name: 'profil', query: {target: "call-profile"}}) 
        },
       

        getStats() {
            this.showOverlay = true
            setTimeout(() => {
                axios.get('notifications').then(response => response.result || []).then(async result => {
                    api.all([
                        await axios.get('stats/nbr-batiments'),
                        await axios.get('stats/nbr-logements'),
                        await axios.get('stats/nbr-locataires'),
                    ]).then(api.spread((batiment, logement, locataire) => {
                        this.nbrBatiments = batiment.result
                        this.nbrLogements = logement.result
                        this.nbrLocataires = locataire.result
                        this.showOverlay = false
                    }))
                    this.$store.dispatch("notifications", result);
                })
            }, 100)
        },
    },
     beforeMount() {
        this.getStats()
    },

}
</script>
<style scoped>


.top-banner{
    position: relative;
    display:flex;
    width:100%;
    height:150px;
    border-radius:5px;
}
.top-banner .img{
    position: relative;
    display:flex;
    width:100%;
    /*background:url(@/assets/img/appart.jpg);*/
    height:90%;
    border-radius:5px;
}
.top-banner .img .bg{
    display:flex;
    width:100%;
    height:100%;
    background:linear-gradient();
    background: rgba(0, 0, 0, .7);
    border-radius:5px;
}
.top-banner .img .txt{
    box-sizing: border-box;
    padding: 3% 5% 0 0;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 70%;
    height: 100%;
}
.top-banner .img .txt h1{
    text-align: right;
    font-weight: 700;
    color: white;
    font-size: 3em;
}
.top-banner .img .txt p{
    font-weight: 500;
    width: 75%;
    text-align: right;
    color: white;
}
.top-banner .img .txt p b{
    font-weight: 800;
    color: #00D1FF;
}
.top-banner .img .txt button{
    height: 45px;
    font-weight: 800;
    color: white;
    background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 11px );
    -webkit-backdrop-filter: blur( 11px );
    border-radius: 2px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );


}
.top-banner .summary{
    position: absolute;
    left: 0;
    bottom: -40%;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 77%;
}
.summary .stat{
    box-sizing: border-box;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80%;
}
.summary .stat .stat-item{
    display: flex;
    align-items: center;
    width: 28%;
    height: 90%;
    border-radius: 5px;
    background: white;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .036);
}
.summary .stat .stat-item i{
    margin: 0 20px;
    color:#f5365c;
}
.summary .stat .stat-item div{
    padding: 5% 0;
    width: 80%;
    height: 100%;
}
.summary .stat .stat-item h3{
    font-weight: normal;
    margin: 0 0 10px;
    font-size: .9rem;
    color:#f5365c;
}
.summary .stat .stat-item span{
    margin: 0;
    font-size: .9rem;
    font-weight: bold;
    color: #1b1b1b;
}
.action-container{
    box-sizing: border-box;
    padding: 4% 5% 0;
    margin: 0;
    display:flex;
    flex-direction: column;
    width:100%;
    height:auto;
    border-radius:5px;
}
.action-container > span{
    font-size: 1.3rem;
    font-weight: bold;
    display: none;
}
.action-container > div{
    margin-top: 10px;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    width: 100%;
    height: auto;
}
.action-container > div .action-item{
    margin-bottom: 2rem;
    display:flex;
    width:49%;
    background:white;
    height: 210px;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .056);
    transition: .3s ease;
}
.action-item .img{
    display:flex;
    width:50%;
    background:rgb(34, 82, 145);
    height:100%;
    border-radius: 8px;
}
.action-item .img img{
    width:100%;
    height:100%;
    border-radius: 8px 0 0 8px;
    object-fit: cover;
}
.action-item .txt{
    box-sizing: border-box;
    padding: 5% 2% 0 5%;
    display:flex;
    flex-direction: column;
    width: 45%;
    height:100%;
    border-radius:2px 0 0 2px;
}
.action-item .txt p{
    color: #4e4e4e;
    font-size: .9rem;
}
.action-item .txt button{
    margin-top: 10px;
    height: 40px;
    border-radius: 4px;
    width: 100%;
    border: none;
    font-weight: 600;
    transition: .3s ease;
    color: white;
    /* background: #191c22; */
    background: transparent;
    color: #020202;
    border: 1px solid #020202;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .026);
}
.action-item .txt button:hover{
    transform: scale(1.05);
    color: white;
    background: #f5365c;
    border: 1px solid #f5365c;
    border: none;
}
.action-item .txt h2{
    font-size: 1.2rem;
    font-weight: bold;
    color: #020202;
}
@media only screen and (max-height: 700px) {
    .top-banner{
    height:auto;
}
.action-container > div .action-item{
    height:40vh;
}
}
@media only screen and (max-width:600px){
    .top-banner .img .txt{
        padding: 1% 5% 0 0;
        width: 90%;
        height: 100%;
    }
    .top-banner .img .txt h1{
        text-align: right;
        font-weight: 700;
        color: white;
        font-size: 2rem;
    }
    .top-banner .img .txt p{
        font-weight: 500;
        width: 90%;
    }
    .top-banner .img .txt p b{
        font-weight: 800;
        color: #00D1FF;
    }
    .top-banner .summary{
        display: none;
    }
    
    .action-container{
        padding: 0;
        margin: 0;
    }
    .action-container > div{
        justify-content:center;
        width: 100%;
        height: auto;
    }
    .action-container > div .action-item{
        margin-top:0;
        flex-direction: column;
        width:100%;
        height:auto;
        min-height: 250px;
        border-radius: 8px;
    }
    .action-item .img{
        display:flex;
        width:100%;
        height:50%;
        border-radius:8px 8px 0 0;
    }
    .action-item .img img{
        border-radius:8px 8px 0 0;
    }
    .action-item .txt{
        margin-top: 0.5rem;
        padding: 5% 5% 0;
        flex-direction: column;
        align-items: flex-end;
        width:100%;
        height:calc(50% - 1.5rem);
    }
    .action-item .txt p{
        text-align: justify;
    }
    .action-item .txt button{
        height: 40px;
        border-radius: 4px;
        width: 100%;
        border: none;
        font-weight: 600;
        transition: .3s ease;
        background: transparent;
        color: #020202;
        border: 1px solid #020202;
    }
}
</style>