export default {
    sidebar_acueil: 'Home',
    sidebar_dashboard: 'Dashboard',
    sidebar_patrimoine_immobilier: 'Real estate',
    sidebar_mes_cites: 'My cities',
    sidebar_mes_batiments: 'My buildings',
    sidebar_mes_logements: 'My accommodations',
    sidebar_gestion_immobiliere: 'Property management',
    sidebar_promotion_immobiliere:'real estate development',
    sidebar_reservations:'Reservations',
    sidebar_mes_annonces: 'My ads',
    sidebar_discussions:'My chats',
    sidebar_mes_occupations: 'My occupations',
    sidebar_locations: 'Rentals',
    sidebar_mes_habitants: 'My tenants',
    sidebar_insolvables: 'Insolvent',
    sidebar_analyse_rentabilite: 'Analysis & Profitability',
    sidebar_depenses: 'Expenses',
    sidebar_notifications:'Notifications',
    sidebar_finances: 'Finance',
    sidebar_releves_indexes: 'Index statements',
    sidebar_loyers: 'Rents',
    sidebar_recettes:'Recipes',
    sidebar_recharge_comptes: 'Recharge accounts',
    sidebar_configuration_aide: 'Documentation and help',
    sidebar_modeles_contrats: 'Contract models',
    sidebar_documentation: 'Documentation',
    sidebar_parametres: 'Settings',
    sidebar_preferences: 'Preferences',
    sidebar_formules_dutilisation: 'Packs',
    sidebar_gestion_locative:'rental management',

    //messages de la homepage
    home_bienvenu: 'Welcome to the maisonier platform',
    home_paragraphe_informatif1: 'Here you can create: cities, buildings, housing, inhabitants. But first, please complete your profile ',
    home_paragraphe_informatif2: 'Full profile at',
    home_terminer_profil: 'Finish your profile',
    home_batiments: 'Buildings',
    home_aucun_batiment: ' No building for the moment',
    home_batiments_crees: 'buildings created',
    home_logements: 'Accommodations',
    home_pas_de_logements: 'No accommodation created for the moment',
    home_logements_crees: ' housing created',
    home_habitants: 'tenants',
    home_pas_dhabitants: 'No tenant created for the moment',
    home_locataires_crees: 'tenants created',
    home_actions_rapides: 'Quick actions',
    home_creer_une_cite: 'Create a city',
    home_descripton_cite: 'A city is a set of several buildings grouped together in an area',
    home_creer: 'Create',
    home_creer_batiment: 'Create a building',
    home_description_batiment: ' A building is a building with several residences (apartments, studios, etc.)',
    home_creer_logement: 'Create accommodation',
    home_description_logement: 'Accommodation is a residence intended to lodge an inhabitant (eg apartment, house, studio)',
    home_creer_habitant: 'Create a tenant',
    home_description_habitant: 'A resident is simply a person (your tenant) who occupies your home',

    //Dashbord message
    dashbord_logements: 'Accommodations',
    dashbord_disponibles: 'Available',
    dashbord_occupe: 'busy',
    dashbord_locataires: 'Tenants',
    dashbord_actifs: 'active',
    dashbord_actif: 'active',
    dashbord_evenements: 'Events',
    dashbord_total_evenement: 'Total Events',
    dashbord_commentaires: 'Comments',
    dashbord_total_commentaire: 'Total comments',
    dashbord_recouvrement_du_mois: 'Monthly collections',
    dashbord_recouvrement_annuel: 'Annual collections',
    dashbord_Logements_par_batiment: "Housing by building",

    //messages de page des cités
    cite_ajouter_cite: 'Add a city',
    cite_pas_de_cite_enregistrer: 'No city registered for the moment',
    cite_detail_cite: 'City details',
    cite_editer_cite: 'City edition',
    cite_valider_cite: 'validate',
    cite_nom_cite: 'Name',
    cite_exemple_cite: 'Eg: City of peace',
    cite_reference_cite: 'Reference',
    cite_exemple_reference_cite: 'Eg: CP3',
    cite_label_photo: 'Photo',
    cite_ajouter_photo_de_la_cite: 'Add the city photo',
    cite_bottom_text: 'drop the file here or click to modify',

    // messages d'un cité
    cite_new: 'New',
    cite_tooltip_details: 'Details',
    cite_tooltip_editer: 'Edit',
    cite_tooltip_supprimer: 'delete',

    //messages de page de détail de la cité
    cite_detail_batiments: 'Buildings',
    cite_detail_stat_generales: 'General statistics',
    cite_detail_ajout_batiments: 'Add buildings',
    cite_detail_depenses_de_la_cite: 'City-related expenses',
    cite_detail_ajouter_depense: 'Add an expense',
    cite_detail_titre_stat_generales: 'General statistics',
    cite_detail_pas_de_batiments: 'No building registered for the moment',
    cite_detail_pas_de_depenses: 'No expense recorded for the moment',


    //messages de page des bâtiments
    batiment_ajouter_batiment: 'Add a building',
    batiment_editer_batiment: 'Building edition',
    batiment_pas_de_batiment: 'No building registered for the moment',
    batiment_pas_de_batiment_trouve: 'No building found',
    batiment_details_batiment: 'Building details',
    batiment_analyses_batiment: 'Building analysis',

    //messages du bâtiment
    batiment_new: 'New',
    batiment_reference: 'Reference',
    batiment_details: 'Details',
    batiment_acceder_aux_details: 'Go to details',
    batiment_editer: 'Edit',
    batiment_supprimer: 'Delete',

    //messages de page de détail de la cité
    batiment_detail_logements: 'Accommodations',
    batiment_detail_stat_generales: 'General statistics',
    batiment_detail_ajouter_logement: 'Add accommodation',
    batiment_detail_depenses_batiment: 'Building expenses',
    batiment_detail_ajouter_depense: 'Add an expense',
    batiment_detail_titre_stat_generales: 'General statistics',
    batiment_detail_pas_de_logements: 'No accommodation registered for the moment',
    batiment_detail_pas_de_depenses: 'No expense recorded for the moment',


    //messages de page de la navbar
    navbar_mes_cites: 'My Cities',
    navbar_mes_batiments: 'My buildings',
    navbar_mes_logements: 'My accommodations',

    //messages de page des logement
    Filtre_de_categories: 'Category filter',
    logement_ajouter_logement: 'Add accommodation',
    logement_pas_de_logement: 'No accommodation registered for the moment',
    logement_details_logement: 'Accommodation details',
    logement_analyses_logement: 'Accommodation analysis',
    logement_cloner_logement: 'Clone housing',
    logement_description_clonage: 'Housing cloning allows you to add a multitude of housings by using pre-existing housing to generate new ones.',
    logement_nombre_de_clones: 'Enter the number of homes to generate',
    logement_nombre_de_clones_label: 'Number of clones',
    logement_batiment_lie_au_clonage: 'Select the building to which the generated dwellings will be attached',
    logement_batiment_lie_au_clonage_label: 'Building',
    logement_pas_de_batiment: 'No building',

    //messages du logement
    logement_etat_libre: 'Free',
    logement_etat_occupe: 'Busy',
    logement_tooltip_details: 'Details',
    logement_tooltip_analyses: 'Analysis',
    logement_tooltip_cloner: 'Clone',
    logement_tooltip_acceder_aux_details: 'Go to details',
    logement_tooltip_editer: 'Edit',
    logement_tooltip_supprimer: 'Delete',

    //messages analyses logement
    logement_analyse_vacances_immobiliere: 'Real estate vacation',

    //messages details logement
    logement_detail_caracteristiques: 'Characteristics',
    logement_detail_annonces: 'Advertisement',
    logement_detail_depenses: 'Expenses',
    logement_detail_locataires: 'Tenants',
    logement_detail_localisation: 'Location',
    logement_detail_localisation_pays: 'Country',
    logement_detail_localisation_ville: 'City',
    logement_detail_localisation_quartier: 'District',
    logement_detail_caracteristiques_approfondies: 'In-depth features',
    logement_detail_type_logement: 'Type of accommodation',
    logement_detail_date_du_poste: 'posted the',
    logement_detail_photos: 'Pictures',
    logement_detail_tableau_libelle: 'Wording',
    logement_detail_tableau_valeur: 'Value',
    logement_detail_titre_etat_logement: 'Housing condition',
    logement_detail_prix_min_logement: 'Minimum price',
    logement_detail_prix_min_logement_exemple: 'Minimum price Ex:18500',
    logement_detail_prix_max_logement_exemple: 'Maximum price Ex:21000',
    logement_detail_prix_min_logement_obligatoire: 'The minimum price is mandatory',
    logement_detail_prix_max_logement_obligatoire: 'The maximum price is mandatory',
    logement_detail_prix_max_logement: 'Maximum price',
    logement_detail_superficie: 'Area',
    logement_detail_pas_de_annonce: 'No announcements recorded for the moment',
    logement_detail_annonce_creee_le: 'Create it',
    logement_detail_ajouter_depense: 'Add an expense',
    logement_detail_pas_de_depense: 'No expense recorded for the moment',
    logement_detail_pas_de_locataire_dans_le_logement: 'No tenant has occupied this accommodation for the moment',
    logement_detail_bail_en_cours: 'Current lease',
    logement_detail_bail_termine_le: 'Lease ended on',
    logement_detail_details_du_locataire: 'Tenant details',
    logement_detail_details_de_occupation: 'Occupancy details',
    logement_detail_tooltip_nom_et_prenom: 'Last name and first name',


    // message de la page du profile de l'utilisateur
    profile_certifier_informations: 'Certify my information',
    profile_certifier_informations_description: "This consists in sending my information for study to the administrators of the platform. Please click on the button to certify your information",
    profile_nombre_locataire: 'Certify my information',
    profile_nombre_logement_libre_sans_annonce_publie: 'The number of my vacancies and without published advertisement',
    profile_address_email: 'E-mail',
    profile_address_email_secondaire: 'Secondary Email',
    profile_address_email_exemple: 'Eg: tagnemiguel@gmail.com',
    profile_address_phone: 'Phone number',
    profile_address_phone_secondaire: 'Secondary phone number',
    profile_address_phone_exemple: 'Eg: 693803726',
    profile_maisonier_sur_reseaux_sociaux: 'The Maisonier on social networks',
    profile_maisonier_facebook: 'Access the Maisonier\'s Facebook page.',
    profile_maisonier_twitter: 'Access the Maisonier\'s Twitter account.',
    profile_maisonier_instagram: 'Access the Maisonier\'s Instagram account.',
    profile_maisonier_youtube: 'Access the Maisonier\'s youtube channel',
    profile_maisonier_whatsap:'Maisonier product whatsapp professional account',
    profile_activite: 'Activity',
    profile_mes_informations: 'My informations',
    profile_nom: 'Name(s)',
    profile_nom_exemple: 'Eg: TAGNE',
    profile_prenom: 'First name(s)',
    profile_prenom_exemple: 'Eg: Miguel Pedro',
    profile_address_cni:'ID Number',
    profile_genre: 'Kind',
    profile_date_naissance: 'Date of Birth',
    profile_selectionner_date: 'Select a date',
    profile_profession: 'Profession',
    profile_profession_exemple: 'Eg: Architecte',
    profile_photo_profile: 'Profile picture',
    profile_modifier_mot_de_passe: 'Change my password',
    profile_mot_de_passe_actuel: 'Current Password',
    profile_mot_de_passe_nouveau: 'New Password',
    profile_mot_de_passe_mettre_a_jour: 'Update',


    //messages des annonces
    annonce_ajouter_une_annonce: 'New Listing',
    annonce_editer_tooltip_annonce: 'Edit',
    annonce_detail_tooltip_annonce: 'Details',
    annonce_menu_tooltip_annonce: 'Menu',
    annonce_pas_de_annonce: 'No ad created for the moment',
    annonce_details_de_annonce: 'Listing details',
    annonce_details_dupliquer_annonce_de_titre: 'Duplication of title ad',
    annonce_details_dupliquer_annonce_de_titre_valider: 'validate',
    annonce_details_dupliquer_annonce_description: 'The duplication of this advertisement will consist in applying it to all the dwellings of the building to which it is linked. In our case, this is all the accommodation in the building',


    //message de l'annonce
    annonce_details_etat_publiee: 'Already published',
    annonce_details_etat_non_publiee: 'Not yet published',
    annonce_details_tooltip_acceder_au_logement: 'Access to accommodation',
    annonce_details_tooltip_publier_annonce: 'Publish the ad',
    annonce_details_tooltip_dupliquer_annonce: 'Duplicate ad',
    annonce_details_tooltip_propositions_annonce: 'Proposals',
    annonce_details_tooltip_commenter_annonce: 'To make a comment',
    annonce_details_tooltip_supprimer_annonce: 'Delete the ad',


    //message détails de l'annonce
    annonce_details_caracteristiques: 'Characteristics',
    annonce_details_propositions: 'Proposals',
    annonce_details_localisation: 'Location',
    annonce_details_pays: 'Country',
    annonce_details_ville: 'City',
    annonce_details_quartier: 'District',
    annonce_details_caracteristiques_approfondies: 'In-depth features',
    annonce_details_titre: 'Title',
    annonce_details_poste_le: 'Posted the',
    annonce_details_photos_annonce: 'Pictures',
    annonce_details_informations_sur_le_logement: 'Accommodation information',
    annonce_details_tableau_libelle: 'Wording',
    annonce_details_tableau_valeur: 'Value',
    annonce_details_prix_logement: 'Accommodation prices',
    annonce_details_propositions: 'Proposals',


    // message du formulaire d'annonce
    annonce_form_titre_annonce: 'Ad Title',
    annonce_form_label_description_annonce: 'Description',
    annonce_form_label_description_annonce_exemple: 'Characterize your ad in order to be well referenced',
    annonce_form_titre_annonce_exemple: 'Ex: Large modern room for rent',
    annonce_form_titre_annonce_description: 'Give your ad an intuitive title',
    annonce_form_label_tags: 'Tags',
    annonce_form_label_tags_exemple: 'Ex: Furnished apartment, large bedroom',
    annonce_form_label_tags_description: 'Add search criteria to your ad',
    annonce_form_label_selection_logement: 'Please select accommodation',
    annonce_form_publier_annonce: 'Directly publish this ad?',
    annonce_form_validite_annonce: 'Specify the validity period',
    annonce_form_validite_label_date_debut_annonce: 'Start date',
    annonce_form_validite_label_date_debut_description_annonce: 'When do you want to start publishing ?',
    annonce_form_validite_label_date_fin_annonce: 'End date',
    annonce_form_validite_label_date_fin_description_annonce: 'When do you want to end the publication ?',
    annonce_form_valider_annonce: 'validate',
    annonce_form_pas_de_logement: 'No accommodation found',
    annonce_form_pas_de_locataire:'No inhabitants found',
    annonce_form_creer_logement: 'Create accommodation',
    annonce_form_explication: 'An advertisement concerns an accommodation so before you can create one, you must first create at least one accommodation',


    //message du formulaire de bâtiment
    batiment_form_wizard_suivant: 'Next',
    batiment_form_wizard_precedent: 'Previous',
    batiment_form_wizard_bouton_enregistrer: 'Save',
    batiment_form_wizard_tab_content_1_titre: 'Basic information',
    batiment_form_wizard_tab_content_2_titre: 'Location',
    batiment_form_label_nom_batiment: 'Name of Building',
    batiment_form_label_nom_batiment_exemple: 'Ex: Building A',
    batiment_form_nom_batiment_obligatoire: 'The name of the building is required',
    batiment_form_label_batiment_reference: 'Reference',
    batiment_form_batiment_reference: 'The reference of the Building is mandatory',
    batiment_form_label_batiment_photo: 'Picture',
    batiment_form_label_batiment_photo_placeholder: 'Add the photo of the building',
    batiment_form_label_batiment_photo_bottom_text: 'Drop the file here or click to edit',
    batiment_form_label_batiment_cite: 'City',
    batiment_form_label_batiment_cite_reference: 'Reference',
    batiment_form_label_batiment_cite_reference_obligatoire: 'The reference of the city is obligatory',
    batiment_form_label_batiment_label_cite_reference: 'City reference',
    batiment_form_label_batiment_label_cite_reference_exemple: 'Ex: CDJ',
    batiment_form_label_batiment_nom_cite: 'City name',
    batiment_form_label_batiment_nom_cite_exemple: 'Ex: City of joy',
    batiment_form_label_batiment_nom_cite_obligatoire: 'The name of the city is required',
    batiment_form_label_batiment_ajouter_photo_cite: 'Add the city photo',
    batiment_form_label_action: 'Action',
    batiment_form_label_ajouter_element: 'Add item',
    batiment_form_label_pays: 'Country',
    batiment_form_label_pays_obligatoire: 'You must select a country',
    batiment_form_label_ville: 'City',
    batiment_form_label_ville_obligatoire: 'Mandatory city',
    batiment_form_label_ville_placeholder: 'city Ex:Yaounde',
    batiment_form_label_quartier: 'District',
    batiment_form_label_quartier_obligatoire: 'Mandatory quarter',
    batiment_form_latitude: 'Latitude',
    batiment_form_longitude: 'Longitude',



    //message du formulaire de logement
    logement_wizard_tab_content_1_titre: 'Accommodation information',
    logement_wizard_tab_content_2_titre: 'Photos of the accommodation',
    logement_wizard_tab_content_3_titre: 'Location of accommodation',
    logement_wizard_tab_content_4_titre: 'In-depth characteristics of the accommodation',
    logement_reference_du_logement: 'Housing reference',
    logement_reference_du_logement_obligatoire: 'This field is required',
    logement_type_logement: 'Housing type',
    logement_categorie_associee: 'Associated category',
    logement_description_logement: 'add a description to your accommodation',
    logement_logement_associe_au_batiment: 'Is your accommodation associated with a building?',
    logement_choisir_batiment_concerne: 'Please choose the building concerned',
    logement_dragText: 'click to choose images',
    logement_browseText: 'You can choose up to 5 images',
    logement_nombre_de_chambre: 'Room number',
    logement_valider_logement: 'Validate',
    logement_nombre_de_cuisine: 'Number of kitchens',
    logement_nombre_de_salon: 'Number of living rooms',
    logement_nombre_de_douche: 'Number of showers',
    logement_nombre_de_parking: 'Number of parking spaces',
    logement_autres_caracteristiques: 'Other characteristic (s)',
    logement_autres_caracteristiques_placeholder: 'Ex: Sona',
    logement_autres_caracteristiques_quantite: 'Quantity Ex: 1',
    logement_form_fermer: 'Close',



    //message du fichier LoadCsv
    loadcsv_modal_title: 'Configure your Template file',
    loadcsv_label_batiment_associe: 'Associated building',
    loadcsv_label_categorie_associe: 'Associated category',
    loadcsv_label_choisir_sous_type: 'You must choose at least one subtype',
    loadcsv_label_telecharger: 'Download',

    //messages de la page gestion
    gestion_menu_mes_annonces: 'My ads',
    gestion_menu_mes_occupations: 'My occupations',


    //messages de l'occupation
    occupation_creer_un_bail: 'Create a lease',
    occupation_pas_de_occupation: 'You have not defined any occupation for the moment',
    occupation_occupations_disponibles: 'Available occupations',
    occupation_bail_termine_le: 'Lease ended on',
    occupation_bail_en_cours: 'Current lease',
    occupation_cloturer_bail:'Close the lease',
    occupation_contrat_occupation: 'Occupation contract',
    occupation_consulter_contrat: 'Consult the contract',
    occupation_editer_occupation: 'Edit occupation',
    occupation_occupation_numero: 'Occupation n °',
    occupation_appercu: 'Overview',
    occupation_impayer_loyer: 'Unpaid rent',
    occupation_impayer_lumiere: 'Unpaid light',
    occupation_impayer_eau: 'Unpaid water',
    occupation_impayer_charges: 'Unpaid charges',
    occupation_total_impayer: 'Total unpaid',
    occupation_locataire: 'Tenant',
    occupation_numero_cni: 'CNI number',
    occupation_numero_telephone: 'Phone number',
    occupation_email: 'Email',
    occupation_date_naissance: 'Date of Birth',
    occupation_voir_dossier: 'See the file',
    occupation_logement: 'Lodging',
    occupation_voir_plage_loyer: 'Rent range',
    occupation_localisation: 'Location',
    occupation_batiment: 'Building',
    occupation_cite: 'City',
    occupation_voir_details: 'See the details',
    occupation_generalite: 'General',
    occupation_loyers: 'Rents',
    occupation_caution: 'Caution',
    occupation_caution: 'Caution',
    occupation_indexes: 'Indexes',
    occupation_comptes: 'Accounts',
    occupation_informations: 'Informations',
    occupation_informations_loyer: 'Rent',
    occupation_informations_mode_paiement: 'Payment method',
    occupation_informations_date_debut_bail: 'Lease start date',
    occupation_informations_date_fin_bail: 'Lease end date',
    occupation_cree_le: 'Occupation created on',
    occupation_duree_prevu_bail: 'Planned lease term',
    occupation_caution_payee: 'Deposit paid',
    occupation_facture_energie: 'Energy billing',
    occupation_facture_eau: 'Water billing',
    occupation_compte: 'Account',
    occupation_creer_compte: 'Create account',
    occupation_effectuer_recharge: 'Recharge',
    occupation_indexes_eau: 'Water indexes',
    occupation_indexes_electricte: 'Electricity indexes',
    occupation_mois: 'Month',
    occupation_annee: 'Year',
    occupation_montant_a_payer: 'Amount to be paid',
    occupation_montant_paye: 'Payroll amount',
    occupation_observation: 'Observation',
    occupation_index_courant: 'Current index',
    occupation_ancien_index: 'Old index',
    occupation_consommation: 'Consommation',
    occupation_consommation_non_consomme: 'Not consumed',
    occupation_consommation_non_payer: 'Outstanding payment',
    occupation_montant: 'Rising',
    occupation_details: 'Details',
    occupation_avance: 'Advance',
    occupation_avancer: 'Advanced',
    occupation_payer: 'Paid',
    occupation_a_payer: 'To pay',
    occupation_date_paiement: 'Payment date',
    occupation_reste: 'Rest',
    occupation_status: 'Status',
    occupation_tooltip_payer_maintenant: 'Pay now',
    occupation_tooltip_completer_paiement: 'Complete the payment',
    occupation_payer_le: 'Pay on',
    occupation_actif: 'Actif',
    occupation_terminer: 'Ended',
    occupation_marquer_comme_terminer: 'Mark as completed',
    occupation_activer_charge: 'Activate the load',
    occupation_details: 'Details',
    occupation_charge_payer: 'To pay',
    occupation_nature_charge: 'Nature of the load',
    occupation_description_charge: 'Description',
    occupation_recharger_compte: 'Nature of the load',
    occupation_recharger_compte_annuler: 'Cancel',
    occupation_compte_a_recharger: 'Account to top up',
    occupation_montant_de_la_recharge: 'Top-up amount',
    occupation_aucun_recu_paiement:'no payment receipt attached to this deposit !!',
    occupation_suppression_depot:'remove deposit',
    occupation_edition_depot:'edit',
    occupation_recu_paiement: 'payment receipt',
    occupation_montant_de_la_recharge_exemple: 'Eg: 5000',
    occupation_creer_compte_pour_occupation: 'Create an account when busy',
    occupation_creer_compte_label_compte: 'Account label',
    occupation_creer_compte_label_compte_exemple: 'Eg: Light',
    occupation_creer_compte_solde_initial: 'Initial balance',
    occupation_creer_compte_solde_initial_exemple: 'Eg: 5000',
    occupation_payer_charge_titre: 'Charge regulation',
    occupation_payer_charge_montant: 'Amount',
    occupation_payer_charge_description: 'Enter the amount to be withdrawn from the account',
    occupation_payer_charge_description_exemple: 'Eg: 5000',
    occupation_reglement_indexe_titre: 'Index regulation',
    occupation_reglement_loyer_titre: 'Rent regulation',


    //messages du contrat de l'occupation
    contratoccupation_aucun_contrat_pour_occupation: 'No contract related to this occupation',
    contratoccupation_imprimer: 'Print',

    //messages du formulaire de l'occupation
    occupation_form_manque_logements:'An occupation concerns a dwelling, so before you can create one, you must first create at least one dwelling.',
    occupation_form_manque_locataires:'An occupation concerns an inhabitant so before you can create one, you must first create at least one inhabitant',
    occupation_form_ajouter_logement:'Add accommodation',
    occupation_form_ajouter_locataire:'Add a resident',
    occupation_form_wizard_tab_content_titre_1: 'Dwelling',
    occupation_form_wizard_tab_content_titre_2: 'Billing',
    occupation_form_wizard_tab_content_titre_3: 'Contract',
    occupation_form_label_logement_de_occupation: 'Which accommodation is affected by occupancy',
    occupation_form_label_logement_de_occupant: 'Who is the resident for whom the occupation is defined',
    occupation_form_label_selectionner_date: 'Select a date',
    occupation_form_label_duree_du_bail: 'Lease term',
    occupation_form_label_duree_du_bail_exemple: 'Eg: 6',
    occupation_form_label_mettre_fin_au_bail_precedent: 'End the previous lease',
    occupation_form_label_combien_doit_payer_le_resident: 'How much will the resident have to pay',
    occupation_form_label_combien_doit_payer_le_resident_exemple: 'Eg: 45000',
    occupation_form_label_nombre_de_mois_avance: 'Number of months in advance',
    occupation_form_label_nombre_de_mois_avance_description: 'How many months has the tenant brought forward',
    occupation_form_label_caution: 'Bail',
    occupation_form_label_caution_description: 'amount paid as a deposit',
    occupation_form_label_indexe_initial: 'Initial index',
    occupation_form_label_indexe_initial_exemple: 'Eg: 095',
    occupation_form_label_indexe_initial_description: 'Water meter index at the entrance of the tenant',
    occupation_form_label_indexe_electricite_initial_description: 'Electricity meter index at the entrance of the tenant',
    occupation_form_taux_forfaitaire: 'Flat rate',
    occupation_form_prix_unitaire_electricite: 'Unit price of electricity',
    occupation_form_prix_unitaire_electricite_exemple: 'Eg: 150',
    occupation_form_indexe_initial_electricite_exemple: 'Eg: 095',
    occupation_form_taux_forfaitaire_description: 'The suggested example assumes consumption by index',
    occupation_form_template_contrat_bail_location: 'lease or rental contract template',
    occupation_upgrade_formule: 'Unable to create a new lease because you have reached the budget for your plan. Please upgrade to a higher plan',
    occupation_upgrade:'Upgrade',
    occupation_cancel: 'Cancel',
    occupation_caution_montant_verse:'Amount paid',

    //messages des locataires insolvables
    ionsolvable_pas_de_insolvable: 'No insolvent tenant was found',
    ionsolvable_en_eau: 'Water',
    ionsolvable_en_lumiere: 'Light',
    ionsolvable_charges_insolvable: 'Charges',
    ionsolvable_total_insolvable: 'Total',
    ionsolvable_voir_dossier_locataire: 'See the tenant\'s file',

    //messages des locations
    locations_mes_habitants: 'My inhabitants',
    locations_Insolvables: 'Insolvent',

    //messages des préférences
    preferences_compte_bailleur: "Donor account",
    preferences_accueil: "Welcome to the Maisonier",
    preferences_accueil_explications: "you have the possibility to configure your language, your currency, and more",
    preference_langue: 'Language',
    preference_langue_anglais: 'English',
    preference_langue_francais: 'French',
    preference_devise: 'Devise',
    preferences_prix_unitaire_eau:'Unit price of water',
    preferences_prix_unitaire_electricite:'Unit price of electricity',
    preferences_TVA: 'TVA',
    preference_devise_actuelle: 'Current currency',
    preference_change_langue: 'Change the language',
    preference_change_devise: 'Change currency',
    preference_choisir_devise: 'Choose currency',
    preference_choisir_langue: 'Choose language',
    preferences_mes_preferences_actuelles: 'My Current Preferences',
    preferences_mise_a_jour: 'Updating Preferences',
    preferences_certifier_mes_informations: 'Certify my information',
    preferences_mes_informations: 'My information',
    preferences_certifier_mon_compte: 'Certify my account',
    preferences_photo_avant_cni: 'Photo before CNI',
    preferences_photo_arriere_cni: 'CNI rear photo',


    //messages de details de l'habitant
    detail_habitant_tooltip_solde_principal: 'Main account balance',
    detail_habitant_impayer_loyer: 'Unpaid rent',
    detail_habitant_impayer_eau: 'Water arrears',
    detail_habitant_impayer_electricite: 'Unpaid electricity bills',
    detail_habitant_impayer_charges: 'Unpaid charges',
    detail_habitant_total_impayer: 'Total unpaid',
    hatitant_detail_messages: 'posts',


    detail_habitant_occupations: 'Occupations',
    detail_habitant_ajouter_occupation: 'Add occupation',
    detail_habitant_numero: 'N°',
    detail_habitant_logement: 'Lodging',
    detail_habitant_prix_loyer: 'Rent price',
    detail_habitant_facture_prepayer: 'Prepaid',
    detail_habitant_facture_postpayer: 'Postpaid',
    detail_habitant_facture_regler: 'rule',
    detail_habitant_facture_non_regler: 'not settled',
    detail_habitant_facture_tooltip_acceder_occupation: 'Access to occupation',
    detail_habitant_pas_encore_publier: 'Not yet published',
    detail_habitant_deja_publier: 'already published',
    detail_habitant_modifier: 'Modify',
    detail_habitant_supprimer: 'Delete',
    detail_habitant_deja_recu: 'you have already received',
    detail_habitant_proposition: 'proposition',
    habitant_form_tab_content_titre_1: 'Personal data',
    habitant_form_tab_content_titre_2: 'Profile picture',
    habitant_form_tab_content_titre_3: 'Other information',
    habitant_form_choix_mode_creation: 'Please choose the creation mode',
    habitant_form_choix_mode_creation_exemple: 'Eg: Please enter the user code',
    habitant_form_envoyer: 'Send',
    habitant_form_nom_obligatoire: 'Compulsory name',
    habitant_form_lieu_naissance: 'Place of birth',
    habitant_form_numero_telephone_exemple: 'Eg: 691202344',
    habitant_form_numero_telephone_obligatoire: 'Mandatory phone number',
    habitant_form_email: 'Eg: tagnemiguel@gmail.com',
    habitant_form_titre_honorifique: 'Honorary title',
    habitant_form_autre_telephone: 'Other phone number',
    habitant_form_autre_telephone_exemple: 'Ex: 671202344',
    habitant_form_autre_mail: 'Other email address',
    habitant_form_autre_numero_cni: 'ID Number',
    habitant_form_autre_numero_cni_exemple: 'Eg: 114989895',
    habitant_form_ajouter_habitant: 'Add a resident',
    habitant_form_pas_de_habitant: 'No inhabitant created for the moment',
    habitant_form_dossier_de: 'File of ',
    locataire_detail_cni_recto:'ICN front ',
    locataire_detail_cni_verso:'ICN back',

    //messages de la liste des dépenses
    liste_depenses_mes_depenses: 'My spend',
    liste_depenses_ajouter_une_depense: 'Add an expense',
    liste_depenses_pas_depenses: 'No expense recorded for the moment',
    liste_depenses_details_depense: 'Expense details',

    //messages du formulaire de depense
    depense_form_label_montant: 'Rising',
    depense_form_label_montant_description: 'how much is the expenditure?',
    depense_form_label_montant_exemple: 'Eg: 45000',
    depense_form_label_date_depense: 'Date the expense was incurred',
    depense_form_label_motif: 'Pattern',
    depense_form_label_motif_description: 'Why did you make this expense?',
    depense_form_label_motif_description_exemple: 'Eg: Replacement of broken bulbs',
    depense_form_label_responsable: 'responsible',
    depense_form_label_responsable_description: 'Who is the one who declared the expense?',
    depense_form_label_observation_placeholder: 'This is where you can describe what caused the expense',
    depense_form_label_joindre_une_photo: 'attach a photo',
    depense_form_label_joindre_une_photo_bottom_text: 'drop the file here or click to modify',
    depense_form_label_joindre_une_photo_bottom_text_placeholder: 'join expense receipt',
    depense_form_label_votre_depense_concerne: 'Your expense concerns',
    depense_form_label_cite_concerne: 'City concerned',
    depense_form_label_batiment_concerne: 'Building concerned',
    depense_form_label_logement_concerne: 'Housing concerned',
    depense_form_non_traiter: 'Not treated',
    depense_form_tooltip_montant_depense: 'Expense amount',
    depense_form_tooltip_date_operation: 'Date of operation',



    //messages de la page des articles
    article_nouvel: 'New',
    article_numero_article: 'Item Number',
    article_details_article_rubrique: 'Rubrics(s)',
    article_details_article_ajouter_rubrique: 'Add rubrics',
    article_details_article_pas_de_rubrique: 'No sections registered for the moment',
    article_details_article_rubrique_description: 'item description',
    article_details_article_rubrique_avoir_valeur: 'Does it have a value?',
    article_details_article_rubrique_description_exemple: 'Eg: They cannot be used even temporarily for other uses than that thus stopped under penalty of full lease termination, followed by immediate abandonment at the first request of the lessor..',
    article_details_article_rubrique_avoir_valeur_oui: 'Yes',
    article_details_article_rubrique_avoir_valeur_non: 'No',
    article_details_article_ajouter_element: 'Add item',

    //messages de la page contrat
    contrat_image_alt: 'lease or rental contract template',

    //messages searchbar
    searchbar_placeholder: 'Search',

    // messages du header
    header_profil: 'Profile',
    header_deconnexion: 'Logout', 


    //messages de la page pagination
    pagination_affichage_de: 'Display of',
    pagination_de: 'of',
    pagination_elements: 'elements',

    //messages de la page printer
    printer_definition_maisonier: 'Real estate management application',

    //messages de la page confirm
    slide_1_titre: 'Easily manage your real estate',
    slide_1_description: 'Take flight and discover a new way to manage your assets. No more need for any assistance. Everything is done in one click.',
    slide_2_titre: 'Do not lose any more information',
    slide_2_description: 'Le Maisonnier collects, processes and stores all your information and lets you use your memory for other things.',
    slide_3_titre: 'Optimize the value of your assets',
    slide_3_description: 'Discover the profitability of your properties, observe your profits continuously, interact with your tenants and much more ...',
    confirm_consulter_mail: 'Please check your mailbox for your 6-digit activation code',
    confirm_pas_compte: 'No account?',
    confirm_creer_compte: 'create an account now',
    confirm_pas_recu_de_code: 'I have not received any code ?',
    confirm_reenvoyer_code: 'Resend',
    confirm_adresse1: 'see the address provided',
    confirm_adresse2: 'hide address',

    //messages de la page subscribe
    subscribe_souscrire: 'Subscribe',

    //messages de la page forgotPassword
    forgotPassword_retrouver_mdp: 'Find your password',
    forgotPassword_addresse_email: 'E-mail address',
    forgotPassword_code_verification: 'Verification code',
    forgotPassword_nouveau_mdp: 'New Password',
    forgotPassword_vous_avez_pas_de_compte: 'You do not have an account ?',
    forgotPassword_inscription: 'You do not have an account?',

    //messages de la page login
    login_se_connecter: 'Log in',
    login_email_telephone: 'Email or phone',
    login_mot_de_passe: 'Password',
    login_mot_de_passe_oublier: 'Forgot your password ?',
    login_connexion: 'Log in',
    login_fournir_une_addresse_email: 'You must provide an email address',
    login_laisser_un_message: 'How can we help you?',

    //messages de la page regisiter
    register_creer_votre_compte: 'Create your account',
    register_homme: 'Man',
    register_femme: 'Women',
    register_entreprise: 'Business',
    register_vous_un_compte: 'Already have an account ?',
    register_connectez_vous: 'Log in',
    register_creer_un_compte: 'Create an account',


    //messages de la page du formulaire de contrat
    contrat_form_wizard_tab_content_titre_1: 'Writing the model',
    contrat_form_wizard_libelle_modele: 'Model label',
    contrat_form_wizard_libelle_modele_exemple: 'Eg: Residential lease contract',
    contrat_form_wizard_libelle_modele_obligatoire: 'Wording is required',

    //messages de la page de listing des contrats
    contrat_creer_un_modele_de_contrat: 'Create a Contract Template',
    contrat_creer_un_modele_de_contrat_description: 'A lease contract or a rental contract is the contract by which one of the parties (called lessor) agrees, for a price (the rent) that the other party (called lessee) is bound by to pay, to procure for it, for a certain time, the enjoyment of a movable or immovable thing.',
    contrat_pas_de_resultat: 'No result found',
    contrat_fermer: 'Close',

    //messages de la page de configuration
    configuration_contrats: 'Contracts',
    configuration_documentation: 'Documentation',

    //messages du formaulaire des Charges
    charge_form_montant: 'Rising',
    charge_form_montant_exemple: 'Eg: 3000',
    charge_form_montant_description: 'How much do you want to allocate?',
    charge_form_montant_label_description: 'Description',
    charge_form_montant_label_description_explication: 'Why do you assign this load?',
    charge_form_type_de_charge: 'Type of load concerned',
    charge_form_statut: 'Status',

     //messages formulaire des Cautions
     caution_form_type_de_caution:'type of deposit',

    //messages de la page des charges
    charge_description: 'Rent sheet',
    charge_pas_de_occupations: 'You have not defined any occupation for the moment',
    charge_loyer_de_base: 'Base rent',
    charge_consommation_en_eau: 'Water consumption',
    charge_consommation_en_lumiere: 'Light consumption',
    charge_effectuer_un_paiement: 'Make a payment',
    charge_correspondance: 'Correspondence',
    charge_ajouter_paiement: 'Adding payment',
    charge_effectuer_versement_pour: 'Make a payment for',
    charge_paiement_loyer: 'Rent payment',
    charge_label_type_paiement_loyer: 'Type of payment',
    charge_label_type_paiement_loyer_description: 'By which channel should we make the payment',
    charge_label_selectionner_methode: 'Select a method',
    charge_label_payer_en_espece: 'Pay in cash',
    charge_label_payer_par_compte_lumiere: 'Pay from a light account',
    charge_label_combien_doit_on_verser: 'How much should we pay',
    charge_label_yaounde_le: 'Yaounde the',
    charge_label_objet: 'Object',
    charge_label_loyer_du_mois_de: 'statement of rents for the month of ',
    charge_label_vous_occuper_le_logement: 'you occupy the accommodation',
    charge_label_vous_occuper_le_logement_situe_a: 'located at',
    charge_label_ci_dessous_le_loyer_du_mois: 'Below is your rent for the month of',

    //messages de la page de recharge des comptes
    recharger_compte_en_masse: 'Bulk account recharge',
    recharger_solde_du_compte: 'Account balance',
    recharger_modifier_le_solde: 'Change the balance',
    recharger_plusieurs_compte: 'Simultaneous recharge of several accounts',
    recharger_pas_de_occupation: 'You have not created any occupation for the moment',
    recharger_solde_actuel: 'Current balance',
    recharger_montant_recharge: 'Enter the amount',


    //messages de la page de finance
    finance_indexes: 'Indexes',
    finance_recharges: 'Refills',
    finance_cautions: 'Cautions',


    //messages de la page des indexes
    indexes_pas_de_indexes: 'You haven\'t saved any index yet ',
    indexes_du_mois_de: 'Indexes of the month of',
    indexes_eau: 'Water',
    indexes_indexes_eau: 'Water index',
    indexes_indexes_electricite: 'Electricity index',
    indexes_electricite: 'Electricity',
    indexes_entrez_nouveaux_indexes: 'Enter the new indexes',
    indexes_mois_passe: 'Past month',
    indexes_popover_indexe_precedent: 'Previous indexes',
    indexes_releves_indexes_du_mois_de:'Energy statement sheet for the month of ',
    indexes_consommation_indexes_du_mois_de:'Energy consumption sheet for the month of',

    //messages de la page de loyers
    loyers_paiement_automatique: 'Start automatic payment',
    loyers_generer_charges: 'Generate loads',
    loyers_generer_les_factures: 'Generate invoices',
    loyers_effectuer_versement_pour: 'Make a payment for',
    loyers_total_a_payer: 'Total to pay',
    loyers_total_a_payer_description: 'This price subtracts the amounts advanced by the tenant for this invoice',
    loyers_montant_a_prelever: 'Amount to be withdrawn',
    loyers_montant_a_prelever_description: 'Enter the amount paid for this invoice. It will be debited from the occupancy account',
    loyers_montant_net_a_payer: 'Net to pay',

    //messages liés au modl de restriction
    modal_restriction_tarifs:'prices',

    cites: 'Cities',
    batiments: 'Buildings',
    logements: 'Accommodations',
    annonces: 'Advertisements',
    habitants: 'Residents',
    insolvables: 'Insolvables',
    depenses: 'Expenses',

    possessions_actuelle: "You currently own",
    total_insolvable: "You currently have",
    avertissement: "Warning",
    avertissement_formule_inadequate: "inadequate formula",
    avertissement_formule_inadequate_description: "Currently, you are using a formula that is inadequate for the action you want to take. This feature is available for paid accounts. You can visit our",
    pour_plus_iunformations: "for more information.",
    payer_formule_access: "pay the ACCESS formula",
    formule_utiilisation_du: "Formulas for the use of",
    utilisation_par_an: "per year",
    souscrire_a_un_pack: "Subscribe",
    souscrire_au_forfait: "Subscription to the package",
    souscrire_au_forfait_facturation: "BILLING",
    souscrire_au_forfait_type: 'TYPE',
    souscrire_au_forfait_moyen_paiement: "method of payment",
    souscrire_au_forfait_moyen_paiement_description: "You can pay by MTN Mobile Money, Orange Money or Paypal.",
    souscription_frequence: "FREQUENCY",
    profile_utilisateur: 'User profile',
    occupation: 'Occupancy',
    occupation_au_total: 'in total',
    occupation_cloturer_bail: 'Closing the lease',
    regler_impayer: 'Resolve unpaid bills',
    repartition_des_impayes: "Distribution of Overdue Items",
    charges_en_eau: 'water expenses',
    charges_en_electricite: 'electricity costs',
    autres_charges: 'other expenses',
    solde_des_comptes: 'Account balances',
    solde: "Balance",
    impayes: 'Unpaid',
    informations_personnelles: 'Personal information',
    afficher_le_logement:"Display the accommodation",
    pas_de_facture_impaye: "This tenant has no unpaid bills",
    nature: "Nature",
    periode: "Period",
    suivi_des_index: "index tracking",
    taux_forfaitaire: "Flat rate",
    paiement_lumiere: "Payment of the light",
    paiement_eau: "Payment for water",
    paiement_eau_type_paiement: 'Type of payment',
    paiement_eau_type_paiement_description: 'Through which channel should the payment be made',
    selectionner_methode: "Select a method",
    payer_en_espece: "Pay in cash",
    payer_a_partir_compte_eau: "Pay from water account",
    canal_paiement: "Through which channel should the payment be made",
    pu_eau: "P.U Water",
    pu_lumiere: "P.U Electricity",
    forfait_eau: "Water package",
    forfait_lumiere: "Electricity package",
    graphe: "Graphs",
    annonce: "Advertisement",

    // message du formulaire de correspondance
    correspondance_envoyer_par_mail: 'Send by mail',
    correspondance_envoyer_par_sms: 'Send by sms',
    correspondance_envoyer_par_whatsapp: 'Send by whatsapp',
    correspondance_envoyer_par_imprimer_facture: 'Print the invoice',
    imprimer_fiche_de_recette: 'Print the recipe card',
    arrieres: 'Arrivals',
    energie: "Energy",
    total_impayes: "TOTAL IMPAYMENTS",
    recettes: "Recipes",
    contacts: "Contacts",

    indexes_non_renseigner: "No information",
    indexes_deja_renseigner: "Already filled in",
    indexes_renseigner_et_non: "All",
    indexes_pas_de_indexes: "No index for this filter",
    indexes_recap_consommation: "Recap of the consumption of",
    indexes_ancien: 'Old',
    indexes_nouveau: 'New',
    indexes_consommation: 'Conso.',
    login_laissez_un_msg: "Leave us a message",

    //messages ribtique des roles
    role_details_retour:"Back",

    reservations_en_cours: "Reservations in progress",
    reservations_en_attentes: "Pending reservations",
    reservations_aprouvees: "Approved reservations",
    reservations_rejetees: "Reservations rejected",
    toutes_les_reservations: "All reservations",

}